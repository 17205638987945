<script setup lang="ts">
import { computed, reactive, watch, onMounted } from 'vue'
import { format } from 'date-fns'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { useGa } from '@/composables/useGa'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import FoodModalLoading from './FoodModalLoading/FoodModalLoading.vue'
import LoadingImage from '@/components/partials/Loading/LoadingImage.vue'
import Amount from '@/components/partials/Amount.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Divider from '@/components/partials/Divider.vue'
import FoodAttribute from './FoodAttribute/FoodAttribute.vue'
import AttributeTypes from '@/config/AttributeTypes'
import { useProductHttp } from '@/http/productHttp'
import { useProduct } from '@/composables/product'
import { DistributionType, type Establishment } from '@/http/models/Establishment'
import type { Location } from '@/types/Location'
import { formatPrice } from '@/plugins/globals/filters'
// import { useEstablishmentHttp } from '@/http/establishmentHttp'

const props = defineProps<{
  establishment: Establishment,
}>()

const { t } = useI18n()
const store = useStore()
const route = useRoute()
const ga = useGa()

const state = reactive({
  // Product selection information (for prop: product)
  amount: 1,
  selectedAttributes: new Map(),
  product: null,

  // To make sure js Maps() are reactive, we need to "hack" a tracker @see: https://stackoverflow.com/a/45441321
  // TODO: remove this when the app is ported to vue 3
  selectedProductPriceTracker: 1,
})

const open = computed(() => !!(route.query.modal === 'food' && route.query.product && route.query.set))

const hasItemsFromEstablishment = computed(() => store.getters['cart/hasItemsFromEstablishment'])
const distributionType = computed(() => store.getters['session/distributionType'])

const { calculatePriceWithAttributes } = useProduct()

const selectedProductPrice = computed(() => {
  if (!state.product) return 0

  // retrieve the price per product and multiply it by the amount
  return (
    state.selectedProductPriceTracker &&
    calculatePriceWithAttributes(state.product.price, state.selectedAttributes, state.product.attributes) * state.amount
  )
})

const emit = defineEmits(['close', 'awaitEmptyCartConfirmation', 'checkTimeslots', 'productNotAvailable', 'openLocationModal'])

const updateAttribute = (attributeId, val) => {
  state.selectedAttributes.set(attributeId, val)

  state.selectedProductPriceTracker += 1
}

const router = useRouter()

const close = () => {
  state.amount = 1

  router.replace({ query: { modal: undefined, product: undefined, set: undefined }})
}

const olderThan18Modal = () => {
  return new Promise((resolve) => {
    store.dispatch('modal/show', {
      dataTestId: 'age-check-modal',
      title: t('modal.ask-age.title'),
      message: t('modal.ask-age.message'),
      closable: false,
      callback: [
        {
          label: t('terms.no'),
          dataTestId: 'age-check-modal-no',
          properties: {
            type: 'secondary',
          },
          action: () => {
            resolve(false)
          },
        },
        {
          label: t('terms.yes'),
          dataTestId: 'age-check-modal-yes',
          action: () => {
            resolve(true)
          },
        },
      ],
    })
  })
}

// const establishmentHttp = useEstablishmentHttp()

async function addToCart() {
  const location = store.getters['session/location'] as Location | null

  if (store.state.session.distributionType === DistributionType.Delivery && location === null) {
    emit('openLocationModal')
    return
  }

  // because we use a Map for no reason, we need to convert it to an array
  // if the value is a string and -1, we remove it because its a placeholder in the select
  // if the value is an object, we need to remove all the false values (checkboxes)
  const filteredAttributes = [...state.selectedAttributes]
    .filter(([k, v]) => {
      if (typeof v === 'string') {
        return v !== '-1'
      }

      return true
    })
    .filter(([k, v]) => {
      if (typeof v === 'object') {
        for (const [key, value] of Object.entries(v)) {
          if (value === false) {
            delete v[key]
          }
        }

        if (Object.keys(v).length === 0) {
          return false
        }
      }

      return true
    })

  // If we close the modal, state.product will be null. We need to save the data before closing.
  const currentProductData = {
    // use raw data instead of resources to avoid double instantiations and loss of data
    // getters will convert the data into resources
    product: state.product,
    productSetId: route.query.set,
    selectedAttributes: new Map([...filteredAttributes]),
    amount: state.amount,
    distributionType: distributionType.value,
  }

  // close modal, so other modals can appear
  close()

  // check if a switch in establishments is happening
  if (!hasItemsFromEstablishment.value) {
    // triggers openEmptyCartConfirmationModal in empty-cart-confirmation-modal.vue
    return emit(
      'awaitEmptyCartConfirmation',
      'switch-establishment',
      (confirm) => {
        if (confirm) {
          store.dispatch('cart/add', currentProductData)
        }
      }
    )
  }

  if (state.product.requiresAgeCheck && !store.getters['session/ageCheckApproved']) {
    const olderThan18 = await olderThan18Modal()

    if (!olderThan18) {
      return
    }

    await store.dispatch('session/approveAgeCheck')
  }

  const productSet = props.establishment.productSets
    ?.find(set => set.id === Number(currentProductData.productSetId))

  const productSetHasTime = productSet?.availableFrom && productSet?.availableUntil

  if (productSetHasTime && props.establishment.overlay?.canOrder) {
    // let basketItems = store.getters['cart/basketItems'].items.map((item) => {
    //   return {
    //     product_id: item.product.id,
    //     product_set_id: item.product.product_set_id,
    //     quantity: item.amount,
    //   }})

    // const params = {
    //   distribution: currentProductData.distributionType,
    //   products: [
    //     ...basketItems,
    //     {
    //       product_id: currentProductData.product.id,
    //       product_set_id: Number(currentProductData.productSetId),
    //       quantity: 1,
    //     },
    //   ],
    // }

    // if (currentProductData.distributionType === DistributionType.Delivery) {
    //   params.location = store.getters['session/location']
    // }

    // const timeSlots = await establishmentHttp.timeslots(props.establishment?.slug, store.state.session.country, route.params.city, params)

    // const firstAvailableSlot = timeSlots[0].slots
    //   .filter(({ time }) => time !== 'ASAP')
    //   .find(({ available }) => available)

    // if (format(new Date(), 'HH:mm') < firstAvailableSlot.time) {
    //   emit('noTimeslots', {
    //     productSetId: currentProductData.productSetId,
    //     time: firstAvailableSlot.time,
    //   })
    // }

    if (productSet !== undefined) {
      const { availableFrom, availableUntil } = productSet

      const now = format(new Date(), 'HH:mm')

      if (now < availableFrom || now > availableUntil) {
        emit('productNotAvailable', availableFrom, availableUntil)
      }
    }
  }

  await store.dispatch('cart/add', currentProductData)
}

const productHttp = useProductHttp()

const fetchProduct = async () => {
  const productId = route.query.product
  const productSetId = route.query.set

  const product = props.establishment.productSets
    .find(productSet => productSet.id === Number(productSetId))
      .products
        .find(product => product.id === Number(productId))

  const attributes = await productHttp.productAttributes(Number(productId), props.establishment.id)

  product.attributes = attributes

  return product
}

function prepareEmptyAttributesArray(attributes) {
  const emptyAttributesArray = new Map()

  attributes.forEach(attribute => {
    if (attribute.type === AttributeTypes.DropDown) {
      emptyAttributesArray.set(
        String(attribute.id),
        String(attribute.subProducts[0].id)
      )
    } else if (attribute.type === AttributeTypes.CheckBox) {
      emptyAttributesArray.set(
        String(attribute.id),
        attribute.subProducts.reduce((prev, current) => {
          prev[String(current.id)] = false
          return prev
        }, {})
      )
    } else if (attribute.type === AttributeTypes.OptionalDropdown) {
      emptyAttributesArray.set(
        String(attribute.id),
        '-1'
      )
    } else {
      emptyAttributesArray.set(String(attribute.id), null)
    }
  })

  return emptyAttributesArray
}

const toggleProduct = async (bool) => {
  if (bool) {
    state.product = await fetchProduct()

    state.selectedAttributes = prepareEmptyAttributesArray(state.product.attributes)

    ga.trackViewProduct({
      product: state.product,
      establishment: props.establishment,
      selectedAttributes: state.selectedAttributes,
    })
  } else {
    state.product = null
  }
}

watch(open, toggleProduct)
onMounted(() => toggleProduct(open.value))
</script>

<template>
  <ModalNuevo no-padding
              :open="open"
              class="food-modal"
              data-test-id="food-modal"
              @close="close">
    <div v-if="state.product"
         data-test-id="add-modal"
         class="food-modal__content"
    >
      <div class="food-modal__img">
        <LoadingImage v-if="state.product.images.cover.sources.small.url"
                      :src="state.product.images.cover.sources.small.url"
                      :srcset="state.product.images.cover.srcSet"
                      :loader-height="$isMobile() ? '220px' : '310px'"
        />
      </div>

      <div class="food-modal__info">
        <p class="food-modal__title">
          {{ state.product.title }}
        </p>
        <div class="food-modal__description">
          {{ state.product.description }}
        </div>

        <template v-if="state.product.attributes.length > 0 && state.selectedAttributes">
          <Divider type="dashed" />

          <FoodAttribute v-for="attribute in state.product.attributes"
                         :key="`attribute_group_${attribute.id}`"
                         :attribute="attribute"
                         :selection="state.selectedAttributes.get(String(attribute.id))"
                         @input="(e) => updateAttribute(String(attribute.id), e)"
          />

          <div class="food-modal__surcharge" v-if="state.product.deposit_surcharge > 0">
            {{  t('messages.has_deposit_surcharge',{ amount: formatPrice(state.product.deposit_surcharge) }) }}
          </div>
          <div class="food-modal__surcharge" v-if="state.product.sup_surcharge > 0">
            {{  t('messages.has_sup_surcharge', { amount: formatPrice(state.product.sup_surcharge) }) }}
          </div>
        </template>
      </div>
    </div>
    <FoodModalLoading v-else />

    <!-- Buttons in Modal Bottom -->
    <template #modal-bottom>
      <Amount v-model="state.amount"
              size="lg"
              :min="1"
              data-test-id="add-modal-amount"
      />
      <Button data-test-id="add-to-cart"
              data-hj-allow
              :disabled="!state.product"
              @click="addToCart"
      >
        {{ t('buttons.add') }} &euro; {{ formatPrice(selectedProductPrice) }}
      </Button>
    </template>
  </ModalNuevo>
</template>

<style lang="scss">
// NOTE: this component is not scoped due to a bug with Vue teleport scoped styling
// NOTE: https://github.com/vuejs/core/issues/2669
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.food-modal {
  // target child component
  .modal__container {
    @include lg-up {
      max-width: 38rem !important;
    }
  }

  &__img img {
    width: 100%;
    object-fit: cover;
    max-height: 220px;

    @include lg-up {
      max-height: 310px;
    }
  }

  &__info {
    padding: 2rem;

    @include lg-up {
      padding: 3rem;
    }
  }

  &__title {
    padding: 0;
    font-weight: 700;
    @include line-lg;
    font-size: 1.125rem;
  }

  &__description {
    padding: 0;
    padding-bottom: 1.25rem;
    color: var(--color-neutral-secondary);
    font-size: 1rem;
  }

  &__surcharge {
    padding: 0;
    color: var(--color-neutral-secondary);
    font-size: 0.9rem;
  }

  &__heading {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 0 0 1rem 0;
  }
}
</style>
