<script setup>
import { ref, useSlots } from 'vue';
import Icon from '@/components/partials/Icon.vue'
import {useI18n} from 'vue-i18n'
const { t } = useI18n()

const slots = useSlots()

const props = defineProps({
  icon: { type: String, default: null },
  copies: { type: String, default: null },
})

const hasRecentlyCopied = ref(false)
const timeout = ref(null)


const copyToClipboard = async (type) => {
  let defaultSlotContent = slots.default()[0].children

  // Do not copy stuff if the default slot does not solely contain text
  if (!props.copies && typeof defaultSlotContent !== 'string') {
    throw new Error(t('errors.copy_clipboard'))
  }

  await navigator.clipboard.writeText(props.copies ?? slots.default()[0].children)

  hasRecentlyCopied.value = true
  clearTimeout(timeout.value)

  timeout.value = setTimeout(() => {
    hasRecentlyCopied.value = false
  }, 2 * 1000)
}
</script>

<template>
  <div class="copy-button"
    @click="copyToClipboard"
  >
    <Icon v-if="icon"
      :name="icon"
    />
    <slot />
    <Icon v-if="!hasRecentlyCopied"
      name="file-copy-line"
      class="copy-button__icon copy-button__icon-copy"
    />
    <div v-else
      class="copy-button__success-message"
    >
      {{ t('terms.copied') }}
      <Icon name="check"
        class="copy-button__icon copy-button__icon-checkmark"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.copy-button {
  background-color: transparent;
  border: 1px solid #EAEAEA;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #F6F6F6;
  }

  &__success-message,
  &__icon {
    margin-left: auto;
  }

  &__success-message {
    font-size: 0.8rem;
    color: var(--color-success);
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &__icon {
    &-copy {
      color: var(--color-neutral-tertiary);
    }

    &-checkmark {
      color: var(--color-success);
    }
  }
}
</style>