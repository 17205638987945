<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import Topic from '../FAQTopic/FAQTopic.vue'
import DistributionTypes from '@/config/DistributionTypes.js'
import EstablishmentStatus from '@/pages/establishment/components/EstablishmentStatus.vue'

const props = defineProps({
  establishment: {
    type: Object,
    required: true,
  },
})

const { t } = useI18n()

const doesTakeAways = computed(() => props.establishment.distributionTypes.includes(DistributionTypes.TakeAway))

const doesDeliveries = computed(() => props.establishment.distributionTypes.includes(DistributionTypes.Delivery))

const hasBothDistributionTypes = computed(() => doesDeliveries.value && doesTakeAways.value)

const translationKey = computed(() => {
  if (hasBothDistributionTypes.value) return 'establishment_faq.opening_hours_both_distribution_types.answer'
  if (doesDeliveries.value) return 'establishment_faq.opening_hours_delivery.answer'
  if (doesTakeAways.value) return 'establishment_faq.opening_hours_takeaway.answer'
  return false
})
</script>

<template>
  <Topic
    v-if="translationKey"
    id="faq-opening-hours"
  >
    <template #question>
      {{ t('establishment_faq.opening_hours.question') }}
    </template>

    <i18n-t
      :keypath="translationKey"
      scope="global"
      tag="span"
    >
      <template #establishment_name>
        {{ props.establishment.title }}
      </template>
      <template #establishment_status_takeaway>
        <EstablishmentStatus
          inline
          no-bubble
          no-capital
          display-only-open-or-closed
          :establishment="props.establishment"
          :distribution-type="DistributionTypes.TakeAway"
        />
      </template>
      <template #establishment_status_delivery>
        <EstablishmentStatus
          inline
          no-bubble
          no-capital
          display-only-open-or-closed
          :establishment="props.establishment"
          :distribution-type="DistributionTypes.Delivery"
        />
      </template>
      <template #opening_hours_link>
        <router-link
          class="establishment-faq__link"
          :to="{query: { modal: 'info'}}"
        >
          {{ t('establishment_faq.view_all_opening_hours') }}
        </router-link>
      </template>
    </i18n-t>
  </Topic>
</template>
