import { ref, computed, onBeforeUnmount } from 'vue'
import { DateTime } from 'luxon'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'

export const STATES = {
  LOADING: -1,
  CLOSED: 0,
  OPEN: 1,
  CLOSING_SOON: 2,
  OPENING_SOON: 3,
}

const DAYS = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
]

export default (props = {}) => {
  const { t } = useI18n()
  const store = useStore()

  const distributionType = computed(() => props.distributionType ?? store.getters['session/distributionType'])
  const establishment = computed(() => props.establishment ?? store.getters['establishment/establishment'])
  const openingHours = computed(() => establishment.value?.openingHours)

  // Create reactive now for computed properties to update on
  const now = ref(DateTime.now())

  const interval = setInterval(() => {
    now.value = DateTime.now()
  }, 60 * 1000) // run every minute to update status

  onBeforeUnmount(() => {
    clearInterval(interval)
  })

  const parseTimeString = (string) =>
    string?.split(':').map((str) => parseInt(str)) ?? []

  const getOpenAndClosedFromBlock = (block = {}) => {
    const [startHour, startMin] = parseTimeString(block.start)
    const [endHour, endMin] = parseTimeString(block.end)

    const openFrom = DateTime.fromObject({
      hour: startHour,
      minute: startMin,
    })

    const closedFrom = DateTime.fromObject({
      hour: endHour,
      minute: endMin,
    })

    const overlapsMidnight = openFrom > closedFrom

    return {
      openFrom,
      closedFrom,
      overlapsMidnight,
    }
  }

  const findCurrentTimeBlock = (blocks) =>
    blocks.find((block) => {
      const { openFrom, closedFrom, overlapsMidnight } =
        getOpenAndClosedFromBlock(block)

      return overlapsMidnight
        ? now.value >= openFrom
        : now.value >= openFrom && now.value < closedFrom
    })

  const currentBlock = computed(() => {
    const day = DAYS[now.value.weekday - 1]
    const yesterday =
      DAYS[now.value.weekday - 2] !== undefined
        ? DAYS[now.value.weekday - 2]
        : DAYS[DAYS.length - 1]

    const hoursForDist = openingHours?.value[distributionType.value]

    if (!hoursForDist) return null

    const hoursYesterday = hoursForDist[yesterday]

    const latestBlockYesterday = hoursYesterday[hoursYesterday.length - 1]
    const currentBlockToday = findCurrentTimeBlock(hoursForDist[day])

    const {
      overlapsMidnight: yesterdayOverlapsMidnight,
      closedFrom: yesterdayClosedFrom,
    } = getOpenAndClosedFromBlock(latestBlockYesterday)
    const inYesterdayOverlappingTimeBlock =
      yesterdayOverlapsMidnight && now.value <= yesterdayClosedFrom

    const { start, end } =
      (inYesterdayOverlappingTimeBlock
        ? latestBlockYesterday
        : currentBlockToday) || {}

    return { start, end }
  })

  const statusCode = computed(() => {
    if (!openingHours.value) return STATES.LOADING

    // If no applicable block is found, the shop is closed
    if (
      !currentBlock.value ||
      !currentBlock.value.start ||
      !currentBlock.value.end
    )
      return STATES.CLOSED

    const { openFrom, closedFrom, overlapsMidnight } =
      getOpenAndClosedFromBlock(currentBlock.value)

    const isClosed = overlapsMidnight
      ? now.value < openFrom && now.value >= closedFrom
      : now.value < openFrom || now.value >= closedFrom

    const isClosingSoon =
      !isClosed &&
      now.value < closedFrom &&
      now.value >= closedFrom.minus({ minutes: 15 })

    const isOpeningSoon =
      isClosed &&
      now.value >= openFrom.minus({ minutes: 15 }) &&
      now.value < closedFrom.minus({ minutes: 15 })

    if (isClosingSoon) return STATES.CLOSING_SOON
    if (isOpeningSoon) return STATES.OPENING_SOON
    if (isClosed) return STATES.CLOSED

    return STATES.OPEN
  })

  const color = computed(() => {
    switch (statusCode.value) {
      case STATES.OPENING_SOON:
      case STATES.CLOSING_SOON:
        return 'orange'
      case STATES.CLOSED:
        return 'red'
      case STATES.OPEN:
        return 'green'
      default:
        return 'grey'
    }
  })

  const statusTextTranslationKey = computed(() => {
    if (props.displayOnlyOpenOrClosed) {
      switch (statusCode.value) {
        case STATES.CLOSING_SOON:
        case STATES.OPEN:
          return 'establishment_status.status_open'
        case STATES.OPENING_SOON:
        case STATES.CLOSED:
          return 'establishment_status.status_closed'
        default:
          return 'loading'
      }
    } else {
      switch (statusCode.value) {
        case STATES.CLOSING_SOON:
          return 'establishment_status.status_closing_soon'
        case STATES.OPENING_SOON:
          return 'establishment_status.status_opening_soon'
        case STATES.CLOSED:
          return 'establishment_status.status_closed'
        case STATES.OPEN:
          return 'establishment_status.status_open'
        default:
          return 'loading'
      }
    }
  })

  const statusText = computed(() => {
    if (props.displayClosingHours) {
      if (
        statusCode.value === STATES.OPEN ||
        statusCode.value === STATES.CLOSING_SOON
      ) {
        return `${t('establishment_status.until')} ${currentBlock.value.end}`
      }
    }

    return t(statusTextTranslationKey.value)
  })

  const timeBeforeClosed = computed(() => {
    const { closedFrom } = getOpenAndClosedFromBlock(currentBlock.value)
    return closedFrom.diff(now.value, ['minutes']).toObject()
  })

  return {
    statusCode,
    statusText,
    timeBeforeClosed,
    color,
  }
}
