<template>
  <LoadingPlaceholder v-if="loading"
    class="loading-image"
    :width="props.loaderWidth"
    :height="props.loaderHeight"
    :loading="loading"
  />
  <template v-else>
    <img v-if="props.tag === 'img'"
      :src="props.src"
      :srcset="props.srcset"
      v-bind="$attrs"
    >
    <component :is="props.tag"
      v-else
      :style="{
        'background-image': 'url(' + props.src + ')',
        'background-image': props.srcset ? `image-set(${props.srcset})` : false
      }"
      v-bind="$attrs"
    />
  </template>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import LoadingPlaceholder from '@/components/partials/Loading/LoadingPlaceholder.vue'

const props = defineProps({
  src: {
    type: String,
    default: null,
  },
  srcset: {
    type: String,
    default: undefined,
  },
  loaderHeight: {
    type: String,
    default: '70%',
  },
  loaderWidth: {
    type: String,
    default: '100%',
  },
  tag: {
    type: String,
    default: 'img',
  },
})

const loading = ref(true)

onMounted(() => {
  if (!props.src) return

  const image = new Image()
  image.src = props.src
  if (image.srcset) image.srcset = props.srcSet
  image.style.display = 'none'

  image.addEventListener('load', () => {
    loading.value = false
  })

  image.addEventListener('error', () => {
    loading.value = false
  })

  document.body.appendChild(image)
})

</script>