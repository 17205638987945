<script setup lang="ts">
import { reactive, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { required, requiredIf, minLength } from '@vuelidate/validators'
import { useSegment } from '@/composables/useSegment'
import { useSentry } from '@/plugins/sentry'
import { useAxios } from '@/composables/useAxios'
import { useStore } from '@/stores'
import { REVIEW_SUBMITTED } from "@/plugins/globals/segment/handlers"
import Checkbox from '@/components/partials/Inputs/Checkbox.vue'
import TextArea from '@/components/partials/Inputs/TextArea.vue'
import TextInput from '@/components/partials/Inputs/TextInput.vue'
import Warning from '@/components/partials/Warning.vue'
import LoadingRow from '@/components/partials/Loading/LoadingRow.vue'
import ReviewStarSelection from './ReviewStarSelection/ReviewStarSelection.vue'
import type { Establishment } from '@/http/models/Establishment'
import { useReviewSubjectsHttp } from '@/http/reviewSubjectsHttp'
import { useOrderHttp } from '@/http/orderHttp'
import { type CreateReview, useReviewsHttp } from '@/http/reviewsHttp'

const DEFAULT_STAR_VALUE = 5

const route = useRoute()
const axios = useAxios()
const sentry = useSentry()
const store = useStore()

const props = defineProps<{
  establishment: Establishment
}>()

const emit = defineEmits(['submitted', 'error'])
const segment = useSegment()

const reviewToken = computed(() => route.params.review)

const state = reactive({
  name: null,
  review: null,

  // placeholders array for loading rows
  subjects: [{ id: 1 }, { id: 2 },{ id: 3 }],
  anonymous: false
})

const rules = computed(() => ({
  name: {
    required: requiredIf(() => !state.anonymous),
  },
  review: {
    required,
    minLength: minLength(3),
  },
}))

const v = useVuelidate(rules, state)

const reviewsHttp = useReviewsHttp()

const submit = async () => {
  await v.value.$validate()

  if (v.value.$error) return

  const review: CreateReview = {
    reviewer: !state.anonymous ? state.name : undefined,
    review_token: reviewToken.value,
    content: state.review,
    ratings: state.subjects
      .map(({ rating, id }) => ({
        review_subject_id: id,
        score: rating,
      }))
  }

  await reviewsHttp.createReview(review)

  emit('submitted', { ...review, subjects: state.subjects })

  segment.handle(REVIEW_SUBMITTED, {
    establishment: props.establishment,
    review: review,
    tracking: store.getters['session/tracking'],
  })
}

const reviewSubjectsHttp = useReviewSubjectsHttp()
const ordersHttp = useOrderHttp()

onMounted(async () => {
  try {
    const data = await reviewSubjectsHttp.getReviewSubject(reviewToken.value)

    state.subjects = data.map(({ id, attributes: { title }}) => ({ id, title, rating: DEFAULT_STAR_VALUE }))

    const order = await ordersHttp.findByReviewToken(reviewToken.value)

    state.name = order.attributes?.customer?.name ?? ''
  } catch (e) {
    emit('error', e.response?.status)
  }
})

defineExpose({ submit })

const { t } = useI18n()
</script>

<template>
  <div class="review-input">
    <div class="review-input__field review-input__row row">
      <div class="col-md-9">
        <TextInput v-if="state.anonymous"
                   disabled
                   :model-value="t('review-input.anonymous')"
                   data-test-id="review-modal-name" />
        <TextInput v-else
                   v-model="state.name"
                   :error="v.name.$error ? t('errors.name.required') : null"
                   :placeholder="t('review-input.placeholder.name')"
                   data-test-id="review-modal-name" />
      </div>
      <div class="col-md-3 review-input__field-checkbox">
        <Checkbox v-model="state.anonymous" data-test-id="review-modal-anonymous">
          {{ t('review-input.anonymous') }}
        </Checkbox>
      </div>
      <div class="col-xs-12">
        <Warning v-if="state.anonymous"
                 class="review-input__anonymity-message"
                 data-test-id="anonymity-message"
                 type="warning">
          {{ t('review-input.anonymous-message') }}
        </Warning>
      </div>
    </div>

    <div class="review-input__field">
      <TextArea v-model="state.review"
                class="review-input__review-text-area"
                data-test-id="review-modal-review"
                :error="v.review.$error ? t('errors.review.required') : null"
                :placeholder="t('review-input.placeholder.review')" />
    </div>

    <div v-for="subject in state.subjects"
         :key="subject.id"
         class="review-input__field"
    >
      <LoadingRow v-if="!subject.title"
                  rounded
                  container-width="100%"
                  container-height="3rem"
      />
      <ReviewStarSelection v-else
                           v-model="subject.rating"
                           :name="subject.title"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.review-input {
  flex-grow: 1;
  min-width: 1px;

  &__field {
    margin-bottom: 1rem;
  }

  &__field-checkbox {
    margin-top: -0.25rem;
    user-select: none;
  }

  &__field-no-review {
    margin-right: 1rem;
    color: var(--color-neutral-secondary);
    font-size: 0.9rem;
  }

  &__anonymity-message {
    font-size: 0.8rem;
    margin-top: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
  }

  &__review-text-area {
    &:deep(.textarea) {
      width: 100%;
    }
  }
}
</style>
