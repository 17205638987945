import { useAxios } from '@/composables/useAxios'

export function useReviewSubjectsHttp() {
  const axios = useAxios()

  async function getReviewSubject(token: string) {
    const { data } = await axios({
      method: 'GET',
      url: `/review-subjects/${token}`,
      showErrorModal: false,
    })

    return data.data
  }

  return {
    getReviewSubject,
  }
}
