<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { useSegment } from '@/composables/useSegment'
import Headline from '@/components/partials/Headline.vue'
import Icon from '@/components/partials/Icon.vue'
import CardsLoadingWrapper from '@/components/partials/Loading/CardsLoadingWrapper.vue'
import LoadingWrapper from '@/components/partials/Loading/LoadingWrapper.vue'
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'
import DishCard from '../../DishCard.vue'
import { PRODUCT_CLICKED } from '@/plugins/globals/segment/handlers'
import type { Establishment } from '@/http/models/Establishment'
import type { ProductSet } from '@/http/models/ProductSet'

type Props = {
  data: ProductSet
  establishment: Establishment
  loadingState?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  loadingState: false,
})

const { t } = useI18n()
const router = useRouter()
const segment = useSegment()
const store = useStore()

const selectProduct = async (product, index) => {
  await router.replace({
    query: {
      modal: 'food',
      product: product.id,
      set: props.data.id,
    },
  })

  segment.handle(PRODUCT_CLICKED, {
    product,
    establishment: props.establishment,
    categoryTitle: props.data.title,
    position: index + 1,
    tracking: store.getters['session/tracking']
  })
}
</script>

<template>
  <div :id="`category_${data.id}`"
       class="establishment-category"
       data-test-id="dish-category"
       :data-category-id="data.id">
    <Headline :hr="false" class="establishment-category__headline">
      <LoadingWrapper :loading="loadingState" width="25%">
        <h2 class="establishment-category__title">
          {{ data.title }}
        </h2>
      </LoadingWrapper>
      <LoadingWrapper :loading="loadingState" width="35%">
        <div class="establishment-category__subtitle" data-test-id="category-sub-title">
          {{ data.description }}
          <span v-if="data.availableFrom !== null && data.availableUntil !== null" class="establishment-category__availability">
            ({{ `${t('terms.from').toLocaleLowerCase()} ${data.availableFrom} ${t('terms.till').toLocaleLowerCase()} ${data.availableUntil}` }})
          </span>
          <div v-if="data.discount" class="establishment-category__discount">
            <Icon name="discount" />
            <span>{{ data.discount.description }} –
              <i18n-t keypath="discount.per.product" scope="global">
                <template #discount>
                  {{ data.discount.amountString }}
                </template>
              </i18n-t>
            </span>
          </div>
        </div>
      </LoadingWrapper>
    </Headline>

    <div class="establishment-category__items">
      <CardsLoadingWrapper :loading="loadingState" width="35%">
        <Row evenly class="establishment-category__dish-row">
          <Column v-for="(product, index) in data.products"
                  :key="product.id"
                  class="col-xxl-6 col-xl-12 col-lg-12 establishment-category__dish-col">
            <DishCard :data="product"
                      :establishment="establishment"
                      @product-selected="(product) => selectProduct(product, index)" />
          </Column>
        </Row>
      </CardsLoadingWrapper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/breakpoints-down.scss';
@import '@/assets/css/variables/variables.scss';
@import '@/assets/css/mixins/styling.scss';

.establishment-category {
  margin: 3rem 0 2rem 0;
  font-size: 16px;

  @include sm-up {
    padding: 0;
  }

  @include lg-up {
    margin: 4rem 0;
  }

  &__headline {
    padding: 0 0.5rem;

    @include lg-up {
      padding: 0;
    }
  }

  &__title {
    font-weight: 700;
    display: block;
    margin: 0 0 5px;
    font-size: 1em;

    @include lg-up {
      font-size: 1.75rem;
    }
  }

  &__subtitle {
    font-weight: 400;
    display: block;
    line-height: 150%;

    @include lg-up {
      font-size: 1rem;
    }
  }

  &__availability {
      font-size: 0.75rem;
  }

  &__dish-row {
    @include lg-up {
      width: 100%;
    }
  }

  &__items {
    margin-top: 1.5rem;
    display: flex;
    gap: 1rem;

    @include lg-up {
      margin-top: 2rem;
    }

    .row {
      [class^='col-'] {
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
      }
    }
  }

  &__discount {
    @include gradient-text;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    gap: 0.5rem;
    font-size: 1rem;

    &:deep(.b-icon-icon path) {
      fill: #FF2AA1;
    }
  }
}
</style>
