<script setup lang="ts">
import { computed, onMounted, onServerPrefetch } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { format, parseISO } from 'date-fns'
import { nl } from 'date-fns/locale'
import { useRoute } from 'vue-router'
import { useEstablishmentHttp } from '@/http/establishmentHttp'
import Icon from '@/components/partials/Icon.vue'
import LoadingRow from '@/components/partials/Loading/LoadingRow.vue'
import type { Establishment } from '@/http/models/Establishment'

type Props = {
  establishment: Establishment
}

const props = defineProps<Props>()

const store = useStore()
const { t } = useI18n()
const route = useRoute()

function endDateText(date: string) {
  return t('discount.stops.at', {
    moment: format(parseISO(date), 'EEEE dd-MM', { locale: nl }),
  })
}

const establishmentHttp = useEstablishmentHttp()

const discounts = computed<any[] | null>(() => store.state.establishment.priceRules)

const fetchDiscounts = async () => {
  const country = route.params.country as string === '' ? undefined : route.params.country as string

  const priceRules = await establishmentHttp.priceRules(
    props.establishment.slug,
    country ?? store.state.session.country,
    (route.params.city as string),
  )

  await store.dispatch('establishment/setPriceRules', priceRules)
}

onServerPrefetch(async () => {
  await fetchDiscounts()
})

onMounted(async () => {
  if (store.state.establishment.ssr) {
    return
  }

  await fetchDiscounts()
})
</script>

<template>
  <div ref="container" class="discount-box" v-if="discounts === null">
    <div ref="slider" class="discount-box__slider">
      <LoadingRow container-width="243px"
                    container-height="90px"
                    rounded
                    v-for="i in 3"
                    :key="i" />
    </div>
  </div>
  <div ref="container" class="discount-box" v-if="discounts !== null && discounts.length > 0">
    <div ref="slider" class="discount-box__slider">
      <div v-for="discount in discounts"
                  data-test-id="discount-item"
                  :key="discount.id"
                  class="discount-box__discount">
        <span class="discount-title"><Icon name="discount" /> {{ discount.title }}</span>
        <span class="discount-description">{{ discount.explanation }}</span>
        <span class="discount-enddate">{{ endDateText(discount.end) }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.discount-box {
  border-bottom: 1px dashed #E5E5E5;

  @include lg-up {
    border-bottom: 0;
    margin: 2.75rem 0 0;
  }

  &__discounts {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
  }

  &__discount {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    gap: 0;
    line-height:1.125rem;
    background-color: #F8F8FA;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;

    border-radius:18px;

    @include lg-up {
      padding: .8rem 1.2rem;
      line-height:1.3rem;
    }

    &:deep(.b-icon-icon) {
      width: 0.9rem;
      vertical-align: middle;
      position:relative;
      top:-1px;
      margin-right:2px;

      & path {
        fill: #FF2AA1;
      }
    }

    .discount-title {
      font-weight: 600;
      font-size: 0.875rem;
      text-transform: uppercase;
      background: linear-gradient(66deg, rgb(255, 42, 161) 0%, rgb(255, 157, 5) 100%);
      margin-bottom:2px;

      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
    }

    .discount-description {
      font-weight: 200;
      font-size: 0.8rem;
      white-space: nowrap;
    }

    .discount-enddate {
      font-weight: 200;
      font-size: 0.75rem;
      color: #9A9797;
    }
  }

  &__slider {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    scrollbar-width: none; /* Firefox */
    scroll-snap-type: x;
    overflow-x: scroll;
    margin: auto;
    height: 90px;

    // no scrollbar
    &::-webkit-scrollbar {
      display: none;
    }

    .discount-box__discount:first-child {
      margin-left: 1rem;
    }

    @include sm-up {
      width: 34rem;
    }

    @include md-up {
      width: 46rem;
      font-size:0.875rem;
      height: 88px;
    }

    @include lg-up {
      width: 58rem;
      height: auto;
      margin-bottom: 10px;

      .discount-box__discount:first-child {
        margin-left: 0;
      }
    }

    @include xl-up {
      width: 71rem;
    }

    @include xxl-up {
      width: 83.5rem;
    }

    @include xxxl-up {
      width: 90rem;
    }
  }
}
</style>
