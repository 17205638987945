<script setup lang="ts">
import { computed } from 'vue'
import { DistributionType, type Establishment } from '@/http/models/Establishment'
import useEstablishmentStatus from '@/pages/establishment/composables/useEstablishmentStatus'
import { useStore } from '@/stores'

const props = defineProps<{
  establishment: Establishment
  distributionType: DistributionType
  openingHours?: Record<string, string>
  noBubble?: boolean
  noColor?: boolean
  noCapital?: boolean
  displayOnlyOpenOrClosed?: boolean
  displayClosingHours?: boolean
  inline?: boolean
  small?: boolean
}>()

const store = useStore()

const establishmentStatus = useEstablishmentStatus(props)

const status = computed(() => {
  if (store.state.session.distributionType === props.distributionType) {
    const status = props.establishment.overlay?.overlay ?? 'Open'

    if (!props.small) {
      return status
    }

    if (status.split(' ').length === 1) {
      return status
    }

    const stripped = status.split(' ').slice(1).join(' ')

    return `${stripped.charAt(0).toUpperCase()}${stripped.slice(1)}`
  }

  return establishmentStatus.statusText.value
})
const color = computed(() => {
  if (store.state.session.distributionType === props.distributionType) {
    return props.establishment.isClosed ? 'red' : props.establishment.overlay?.overlay ? 'grey' : 'green'
  }

  return establishmentStatus.color.value
})
</script>

<template>
  <div
    class="establishment-status"
    :class="{
      [`establishment-status--${color}`]: !props.noColor,
      'establishment-status--no-bubble': props.noBubble,
      'establishment-status--inline': props.inline
    }"
  >
    <div class="establishment-status__indicator" /> {{ status }}
  </div>
</template>

<style lang="scss" scoped>
.establishment-status {
  $self: &;
  display: flex;
  gap: 0.75rem;
  align-items: center;

  &__indicator {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 1rem;
  }

  &--green {
    color: #33cf71;

    #{$self}__indicator {
      background: #33cf71;
    }
  }

  &--orange {
    color: #ff9821;

    #{$self}__indicator {
      background: #ff9821;
    }
  }

  &--red {
    color: var(--color-brand-primary);

    #{$self}__indicator {
      background: var(--color-brand-primary);
    }
  }

  &--grey {
    color: var(--color-neutral-secondary);

    #{$self}__indicator {
      background: var(--color-neutral-secondary-40);
    }
  }

  &--no-bubble {
    #{$self}__indicator {
      display: none;
    }
  }

  &--inline {
    display: inline-flex;
  }
}
</style>
