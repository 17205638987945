<script setup lang="ts">
import { useRouter } from 'vue-router'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import Container from '@/components/Structure/Container/Container.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import EstablishmentHeaderVisual from './EstablishmentHeaderVisual.vue'
import InfoBox from './InfoBox.vue'
import MenuMobileRedesign from '@/components/Menu/MenuMobileRedesign.vue'
import { useResizeObserver } from '@/composables/resizeObserver'
import type { Establishment } from '@/http/models/Establishment'

type Props = {
  establishment: Establishment
}

defineProps<Props>()

const router = useRouter()
const { localizedRoute } = useLocalizedRouter()

const { val } = useResizeObserver()

async function openInfoModal(trigger: string) {
  if (trigger === 'info-button') {
    await router.push({ query: { modal: 'info' } })
  }

  if (trigger === 'info-box' && val.value >= 992) {
    await router.push({ query: { modal: 'info' } })
  }
}

async function gotoMarketplace() {
  await router.replace(localizedRoute({ name: 'marketplace' }))
}
</script>

<template>
  <div class="establishment-header" data-test-id="establishment-header">
    <EstablishmentHeaderVisual />

    <MenuMobileRedesign share-button @logo-click="gotoMarketplace" />

    <Container>
      <div class="establishment-header__content">
        <div class="establishment-header__logo-and-text">
          <div class="establishment-header__establishment-logo"
               :style="{ backgroundImage: `url('${establishment.avatar}')` }"
               v-if="establishment.avatar !== null"></div>
          <div class="establishment-header__establishment-logo" v-else>
            {{ establishment.logoCharacters() }}
          </div>

          <div class="establishment-header__text">
            <h1 class="establishment-header__name">
              {{ establishment.title }}
              <Button class="establishment-header__info-button"
                      icon="info-no-circle"
                      type="secondary-inverted"
                      size="xs"
                      @click="openInfoModal('info-button')" />
            </h1>
            <h2 class="establishment-header__categories">
              {{ establishment.categoriesString }}
            </h2>
          </div>
        </div>
        <InfoBox :establishment="establishment" @click="openInfoModal('info-box')" />
      </div>
    </Container>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/breakpoints-down.scss';

.info-box-skeleton {
  // important is needed here because of the default values in the LoadingRow component
  width: unset !important;
  height: 92px !important;
  border-radius: 1rem;


  @include sm-down {
    margin: 0 1rem;
    height: 45px !important;
    border-radius: 0;
  }

  @include lg-up {
    margin-right: -8rem;
  }

  @include xl-up {
    margin-right: 2rem;
  }

  @include xxl-up {
    margin-right: -2.75rem;
  }
}

.establishment-header {
  $self: &;
  position: relative;
  background-color: #fff;
  padding-bottom: 1rem;

  @include lg-up {
    padding: 14rem 0 3rem 0;
  }

  @include xxl-up {
    padding: 15rem 0 5.75rem 0;
  }

  &__establishment-logo {
    position: absolute;
    top: -7.5rem;
    left: 0.5rem;
    border: 0.5rem solid white;
    width: 6.5rem;
    height: 6.5rem;
    flex: 0 0 6.5rem;
    border-radius: 9999999px;
    background-color: #e3e3e3;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    color: #fff;
    font-size: 2rem;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 1px 1px 0.5rem rgba(0, 0, 0, 0.1);
    background-size: cover;

    @include lg-up {
      position: relative;
      top: auto;
      left: auto;
      border: none;
      box-shadow: none;
    }
  }

  &__content {
    position: relative;
    padding: 2rem 1rem 1rem 1rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include lg-up {
      max-width: 60%;
      gap: 3rem;
      padding: 0;
    }
  }

  &__logo-and-text {
    position: relative;

    @include lg-up {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }

  &__text {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;

    @include lg-up {
      width: calc(100% - 8.5rem);
      padding: 0;
    }
  }

  &__description {
    display: none;

    @include lg-up {
      display: block;
    }
  }

  &__name {
    font-size: 1.625rem;
    display: flex;
    align-items: center;
    margin-right: -1rem;

    @include lg-up {
      margin-right: 0;
      font-size: 3rem;
    }
  }

  &__info-button {
    margin-left: auto;

    @include lg-up {
      display: none !important; // TODO: remove the !important after vite css chunk problem is solved
    }
  }

  &__categories {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include lg-up {
      font-size: 1.25rem;
      height: 1.875rem;
    }
  }
}
</style>
