<script setup>
import Icon from '@/components/partials/Icon.vue';
import { ref, computed } from 'vue';

const props = defineProps({
  expanded: { type: Boolean, default: false }
})

const userValue = ref(null)

const isExpanded = computed(() => userValue.value ?? props.expanded)
</script>

<template>
  <div class="faq-topic"
       :class="{ 'faq-topic--expanded': isExpanded}"
  >
    <div class="faq-topic__question"
         @click="userValue = userValue ? false : true"
    >
      <slot name="question" />
      <Icon name="arrow"
            class="faq-topic__arrow"
      />
    </div>
    <div v-show="isExpanded">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.faq-topic {
  $self: &;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  &__question {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
    line-height: 2;
  }

  &__arrow {
    transition: all 0.2s;
    transform: translateY(0.25rem) scale(0.8);
  }

  &--expanded {
    #{$self}__arrow {
      transform: rotate(-180deg) translateY(0.15rem) scale(0.8);
    }
  }
}
</style>
