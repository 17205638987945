<template>
  <div class="form-group">
    <label
      :class="{ checked: checked }"
      class="cursor-pointer checkbox"
    >
      <input
        v-taptic="'selection'"
        type="checkbox"
        :name="name"
        :checked="checked"
        :value="value"
        class="cursor-pointer"
        :data-test-id="dataTestId"
        @change="change"
      >
      <slot>Checkbox label text</slot>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FoodAttributeCheckbox',

  props: {
    name: {
      type: String,
      default: `checkbox_${Math.floor(Math.random() * 100 + 1)}`,
    },
    value: { type: Boolean, default: false },
    dataTestId: { type: String, default: null },
  },

  emits: ['input'],

  data() {
    return {
      checked: this.value,
    }
  },

  methods: {
    change() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/interactions.scss';
@import '@/assets/css/mixins/styling.scss';

.checkbox {
  display: flex;
  align-items: center;
  transition: color 110ms ease-out;
  [type='checkbox'] {
    width: 1.25rem;
    height: 1.25rem;
    border: 1px solid var(--color-neutral-tertiary);
    border-radius: 0.25rem;
    margin-right: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-appearance: none;
    transition: border 150ms ease-out;

    &:before {
      content: url('/assets/img/check.svg');
      display: block;
      position: relative;
      width: 1rem;
      top: 0.125rem;
    }

    &:checked {
      border: 1px solid var(--color-brand-primary);
      @include gradient-bg;

      &:before {
        opacity: 1;
        transition: opacity 110ms ease-out;
      }
    }
  }

  @include hover {
    &:hover {
      cursor: pointer;

      [type='checkbox'] {
        border: 1px solid var(--color-brand-primary);
        transition: border 150ms ease-out;
      }
    }
  }
}

.form-group {
  display: flex;
  align-items: center;
}
</style>
