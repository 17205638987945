<script setup lang="ts">
import { ref } from 'vue'
import StarSelect from '@/assets/img/icons/star_select.svg?raw'

defineProps({
  modelValue: { type: Number, required: true },
  error: { type: String, default: null },
  name: { type: String, default: '' },
})

const emit = defineEmits(['update:modelValue'])

const hoverValue = ref(0)

function onStarClick(i, e) {
  emit('update:modelValue', determineStarValue(i, e))
}

function onStarHover(i, e) {
  hoverValue.value = determineStarValue(i, e)
}

function determineStarValue(starIndex, event) {
  const { left, width } = event.target.getBoundingClientRect()
  const oneHalf = width / 2
  const clickPosition = event.clientX - left
  const fullStar = clickPosition > oneHalf
  return fullStar ? starIndex : starIndex - 0.5
}
</script>

<template>
  <div class="star-selection"
    :class="{ 'star-selection--error': error !== null }"
  >
    <div class="star-selection__stars-selection_name">
      <span class="star-selection__stars-selection_bold">{{ name }}</span>
      <span v-if="error !== null"
        class="star-selection__message"
      >
        {{ error }}
      </span>
    </div>

    <div @mouseleave="hoverValue = 0">
      <div class="star-selection__stars-selection_picker">
        <div class="star-selection__stars">
          <div v-for="i in 5"
            :key="i"
            class="star-selection__star-wrapper cursor-pointer"
            :data-test-id="`review-modal-star_${name.toLowerCase()}_${i}`"
            @click="onStarClick(i, $event)"
            @mouseover="onStarHover(i, $event)"
          >
            <!-- eslint-disable vue/no-v-html -->
            <div class="star-selection__star"
              v-html="StarSelect"
            />
            <div class="star-selection__star"
              :class="{
                'star-selection__star--hover': hoverValue >= i,
                'star-selection__star--active': !hoverValue && modelValue >= i,
                'star-selection__star--half': hoverValue
                  ? hoverValue === i - 0.5
                  : modelValue === i - 0.5,
              }"
              v-html="StarSelect"
            />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.star-selection {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed var(--color-neutral-tertiary);
  padding: 0 0 1rem 0;
  width: 100%;

  &__stars-selection_name {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
  }

  &__stars-selection_bold {
    font-weight: 700;
  }

  &__stars {
    display: flex;
  }

  &__star-wrapper {
    position: relative;
    border-radius: 50%;
    padding: 0.2rem 0.3rem;
    width: 2rem;
    height: 2rem;
    border: 1px var(--color-neutral-quaternary) solid;
    margin: 0 0.25rem;
  }

  &__star {
    position: absolute;
    width: 100%;
    left: 0;
    color: var(--color-neutral-tertiary);
    text-align: center;

    &--active,
    &--hover,
    &--half {
      color: var(--color-brand-lighter);
    }

    &--half {
      overflow: hidden;
      clip-path: polygon(0 0, 50% 0, 50% 100%, 0% 100%);
    }
  }

  &__message {
    font-size: 0.9rem;
    color: #e43e3e;
    display: block;
    margin: 0 0.75rem;
  }
}
</style>
