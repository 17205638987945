<template>
  <div class="form-group">
    <select v-taptic="'selection'"
      class="select"
      :data-test-id="dataTestId"
      data-hj-allow
      @input="(e) => $emit('input', e.target.value)"
    >
      <option v-if="placeholder" :key="null" :value="-1">{{ placeholder }}</option>
      <option v-for="(option, index) in items"
        :key="option.id"
        :value="option.id"
      >
        {{ option.title }} (+ &euro; {{ $formatPrice(totalPrice(option)) }})
      </option>
    </select>
  </div>
</template>

<script setup>
defineProps({
  items: {
    type: Array,
    required: true,
  },

  value: { type: String, default: null },
  placeholder: { type: String, required: false, default: null },

  dataTestId: { type: String, default: null },
})

defineEmits(['input'])

const totalPrice = (value) => {
  let price = value.price
  if (value.depositSurcharge) {
    price += value.depositSurcharge
  }
  if (value.supSurcharge) {
    price += value.supSurcharge
  }
  return price
}

</script>
<style lang="scss" scoped>
@import '@/assets/css/mixins/interactions.scss';

.select {
  padding: 0.75rem 2em 0.75rem 1rem;
  margin: 0;
  border: 1px solid var(--color-neutral-quaternary);
  border-radius: 0.5rem;
  width: 100%;
  line-height: 1.4;
  -webkit-appearance: none;
  background-color: #fff;
  background-image: url('/assets/img/icons/chevron.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 1rem) center;
  color: var(--color-neutral-primary);

  &:focus {
    outline: none;
    border: 1px solid var(--color-brand-primary);
  }

  @include hover {
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
