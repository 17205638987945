<script setup lang="ts">
import { computed, onMounted, onServerPrefetch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import { useRoute } from 'vue-router'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import Headline from '@/components/partials/Headline.vue'
import Topic from './FAQTopic/FAQTopic.vue'
import FAQOpeningHours from './FAQOpeningHours/FAQOpeningHours.vue'
import { useEstablishmentHttp } from '@/http/establishmentHttp'
import type { Establishment } from '@/http/models/Establishment'

type Props = {
  establishment: Establishment
}

const props = defineProps<Props>()

const { t, locale } = useI18n()
const { localizedRoute } = useLocalizedRouter()
const store = useStore()
const route = useRoute()

const { faq } = useEstablishmentHttp()

async function fetchFAQ() {
  const country = route.params.country as string === '' ? undefined : route.params.country as string

  const response = await faq(
    props.establishment.slug,
    country ?? store.state.session.country,
    (route.params.city as string)
  )

  await store.dispatch('establishment/setEstablishmentFaq', response)
}

onServerPrefetch(async () => {
  await fetchFAQ()
})

onMounted(async () => {
  if (store.state.establishment.ssr) {
    return
  }

  await fetchFAQ()
})

const establishmentFaq = computed(() => store.getters['establishment/faq'])
</script>

<template>
  <div class="establishment-faq">
    <Headline>
      <h2>{{ t('establishment_faq.header.title') }}</h2>
      <p>
        <i18n-t keypath="establishment_faq.header.description"
                scope="global"
                tag="span"
        >
          <template #bistroo_customer_service>
            <RouterLink class="establishment-faq__link"
                        :to="localizedRoute({ name: 'customer-support' })">
              {{ t('establishment_faq.the_customer_service') }}.
            </RouterLink>
          </template>
        </i18n-t>
      </p>
    </Headline>

    <div v-if="establishment"
         class="establishment-faq__topics"
    >
      <Topic id="faq-changes-in-order-after-payment">
        <template #question>
          {{ t('establishment_faq.make_changes_in_order.question') }}
        </template>

        <i18n-t keypath="establishment_faq.make_changes_in_order.answer"
                scope="global"
                tag="span"
        >
          <template #establishment_name>
            {{ establishment.title }}
          </template>
          <template #establishment_phone>
            <a class="establishment-faq__link"
               :href="`tel:${establishment.phone}`"
               target="_blank"
            >
              {{ establishment.phone }}
            </a>
          </template>
        </i18n-t>
      </Topic>

      <Topic id="faq-something-wrong-with-order">
        <template #question>
          {{ t('establishment_faq.something_wrong_with_order.question') }}
        </template>
        <i18n-t keypath="establishment_faq.something_wrong_with_order.answer"
                scope="global"
                tag="span"
        >
          <template #establishment_phone>
            <a class="establishment-faq__link"
               :href="`tel:${establishment.phone}`"
               target="_blank"
            >
              {{ establishment.phone }}
            </a>
          </template>
          <template #bistroo_customer_service>
            <router-link class="establishment-faq__link"
                         :to="localizedRoute({ name: 'customer-support' })"
            >
              {{ t('establishment_faq.the_customer_service') }}
            </router-link>
          </template>
        </i18n-t>
      </Topic>

      <FAQOpeningHours :establishment="establishment" />

      <template v-for="item in establishmentFaq"
                :key="item.id">
        <Topic v-if="item.attributes.question[locale] && item.attributes.answer[locale]"
               :id="`faq-${item.id}`"
               data-test-id="custom-faq-topic">
          <template #question>
            {{ item.attributes.question[locale] }}
          </template>

          {{ item.attributes.answer[locale] }}
        </Topic>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.establishment-faq {
  &__link,
  &:deep(.establishment-faq__link) {
    color: var(--color-brand-primary);
  }
}
</style>
