<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { format } from 'date-fns'
import { enUS, nl } from 'date-fns/locale'
import Reviews from '@/components/Reviews/Reviews.vue'
import type { Review } from '@/types/Review'

const props = defineProps<Review>()

const { t, locale } = useI18n()

const name = computed(() => props.name ?? t('review-input.anonymous'))

const locales = { nl, en: enUS }

const date = format(props.date, 'EEEE d MMMM', {
  locale: locales[locale.value],
})
</script>

<template>
  <div class="review-comment__content">
    <div class="review-comment__details">
      <div class="review-comment__header">
        <p class="review-comment__header-user">
          {{ name }}
        </p>
        <p class="review-comment__header-date">
          {{ date }}
        </p>
      </div>
      <p class="review-comment__description">
        {{ content }}
      </p>
    </div>
    <div class="review-comment__ratings">
      <div v-for="rating in ratings"
           :key="rating.name"
           class="review-comment__rating">
        <p class="review-comment__rating-title">
          {{ rating.name }}
        </p>
        <Reviews :rating="rating.score" class="reviews__stars" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
.review-comment {
  $self: &;
  flex-grow: 1;

  &__stars {
    margin: 0.3rem 0 0 -0.2rem;

    @include sm-up {
      margin: 0;
    }
  }

  &__content {
    @include sm-up {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }
  }

  &__details {
    @include sm-up {
      width: 100%;
    }
  }

  &__ratings {
    display: flex;
    justify-content: space-between;

    @include sm-up {
      justify-content: flex-start;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__rating {
    @include sm-up {
      display: flex;
      gap: 1rem;
      justify-content: flex-end;
      align-items: center;

      &:first-child {
        margin-top: 2.5rem;
      }
    }
  }

  &__header {
    display: flex;
  }

  &__header-user {
    font-weight: 700;
  }

  &__header-date {
    font-style: italic;
    color: var(--color-neutral-secondary);
    margin-left: 0.5rem;
  }

  &__description {
    margin: 1rem 0 2rem;

    @include sm-up {
      margin-bottom: 0;
    }
  }
}
</style>
