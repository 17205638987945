<script setup lang="ts">
import { useI18n } from 'vue-i18n'

const props = defineProps({
  openingHours: { type: Object, default: null },
})

const { t } = useI18n()
</script>

<template>
  <ul class="opening-hours">
    <li
      v-for="(times, day) in props.openingHours"
      :key="day"
      class="opening-hours__line"
    >
      <div class="opening-hours__day">
        {{ t(`terms.${day}`) }}
      </div>
      <div class="opening-hours__times">
        <p
          v-for="(label, i) in times.map(
            (time) => `${time.start} – ${time.end}`
          )"
          :key="i"
          data-hj-allow
          v-text="label"
        />
        <p v-if="times.length === 0">
          {{ t('terms.closed') }}
        </p>
      </div>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-down.scss';
@import '@/assets/css/mixins/styling.scss';

.opening-hours {
  margin-left: 0;
  @include line-lg;

  &__line {
    color: var(--color-neutral-primary-80);
    display: flex;
    justify-content: space-between;
  }
}
</style>
