<template>
  <div
    :key="`attribute_${attribute.id}`"
    class="modal-food__checklist"
  >
    <h4 class="modal-food__heading">
      {{ attribute.title }}
    </h4>

    <template v-if="attribute.subProducts.length > 0">
      <FoodAttributeDropdown
        v-if="attribute.type === AttributeTypes.DropDown || attribute.type === AttributeTypes.OptionalDropdown"
        :value="selection"
        :items="attribute.subProducts"
        :placeholder="attribute.type === AttributeTypes.OptionalDropdown ? attribute.optionalPlaceholder : null"
        data-test-id="add-modal-dropdown"
        @input="(newValue) => emitNewDropdownValue(newValue)"
      />

      <template v-if="attribute.type === AttributeTypes.CheckBox">
        <div
          v-for="subProduct in attribute.subProducts"
          :key="`subProduct_${subProduct.id}`"
          class="modal-food__check"
          data-test-id="modal-food-checklist-item"
        >
          <FoodAttributeCheckbox
            :value="selection[subProduct.id]"
            data-test-id="add-modal-checkbox"
            @input="
              (newValue) =>
                emitNewCheckboxValue(newValue, selection, subProduct.id)
            "
          >
            {{ subProduct.title }}
          </FoodAttributeCheckbox>
          <div
            class="modal-food__check-price"
            data-hj-allow
          >
            + &euro; {{ $formatPrice(subProduct.price) }}
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import FoodAttributeCheckbox from './FoodAttributeCheckbox/FoodAttributeCheckbox.vue'
import FoodAttributeDropdown from './FoodAttributeDropdown/FoodAttributeDropdown.vue'
import AttributeTypes from '@/config/AttributeTypes'

export default {
  name: 'FoodAttribute',

  components: { FoodAttributeDropdown, FoodAttributeCheckbox },

  props: {
    selection: { type: [String, Object], required: true },
    attribute: { type: Object, required: true },
  },

  emits: ['input'],

  data() {
    return { AttributeTypes }
  },

  computed: {
    //
  },

  methods: {
    emitNewDropdownValue(newValue) {
      this.$emit('input', newValue)
    },

    emitNewCheckboxValue(newValue, currentSelection, productId) {
      // Set the product value to either true or false
      currentSelection[productId] = newValue
      this.$emit('input', currentSelection)
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-food {
  &__heading {
    font-size: 1.125rem;
    font-weight: 900;
    margin: 0 0 1rem 0;
  }

  &__checklist {
    margin: 2rem 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }


  &__check {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-group {
      margin: 0.75rem 0;
    }
  }

  &__check-price {
    flex-shrink: 0;
    color: var(--color-neutral-secondary);
  }

  &__check {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .form-group {
      margin: 0.75rem 0;
    }
  }

  &__check-price {
    flex-shrink: 0;
    color: var(--color-neutral-secondary);
  }
}
</style>
