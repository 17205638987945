import { useAxios } from '@/composables/useAxios'

export type CreateReview = {
  reviewer?: string
  review_token: string
  content: string
  ratings: {
    review_subject_id: number
    score: number
  }[]
}

export function useReviewsHttp() {
  const axios = useAxios()

  async function createReview(review: CreateReview) {
    const { data } = await axios({
      method: 'POST',
      url: `/reviews`,
      data: review,
    })

    return data.data
  }

  return {
    createReview,
  }
}
