<template>
  <div
    class="amount"
    :class="{ ['amount--' + size]: size }"
    :data-test-id="`${dataTestId}`"
    @click.prevent
  >
    <div
      v-taptic="'selection'"
      class="amount__btn"
      :class="{ 'amount__btn--disabled': disabled }"
      :data-test-id="`${dataTestId}-decrement-button`"
      @click.prevent="
        $emit(
          'update:modelValue',
          modelValue - step >= min ? modelValue - step : modelValue
        )
      "
    >
      <Icon name="subtract" />
    </div>
    <div
      v-if="currency"
      class="amount__value"
      :class="{ 'amount__value--disabled': disabled }"
    >
      &euro; {{ $formatPrice(modelValue) }}
    </div>
    <div
      v-else
      data-hj-allow
      class="amount__value"
      :class="{ 'amount__value--disabled': disabled }"
    >
      {{ modelValue }}
    </div>
    <div
      v-taptic="'selection'"
      class="amount__btn"
      :data-test-id="`${dataTestId}-increment-button`"
      @click.prevent="
        $emit(
          'update:modelValue',
          modelValue + step <= max ? modelValue + step : modelValue
        )
      "
    >
      <Icon name="add" />
    </div>
  </div>
</template>

<script>
// Component imports
import Icon from '@/components/partials/Icon.vue'

export default {
  name: 'Amount',

  components: { Icon },

  props: {
    modelValue: { type: Number, default: 0 },
    step: { type: Number, default: 1 },
    min: { type: Number, default: 0 }, // inclusive
    max: { type: Number, default: 100 }, // inclusive
    size: { type: String, default: 'md' },
    currency: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    dataTestId: { type: String, default: null },
  },

  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/interactions.scss';

.amount {
  display: flex;
  align-items: center;

  &__btn {
    font-size: 0.5rem;
    border: 1px solid var(--color-neutral-tertiary);
    color: var(--color-neutral-secondary);
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition: transform 150ms ease-out;
    cursor: pointer;

    @include icon-svg(0.5rem, 0.5rem);
    @include hover {
      &:hover {
        cursor: pointer;
        transform: scale(1.2);
        transition: transform 150ms ease-out;
      }
    }
    &--disabled {
      border: 1px solid var(--color-neutral-quaternary);
      color: var(--color-neutral-quaternary);
    }
  }
  &__value {
    font-weight: 700;
    margin: 0 0.5rem;
    &--disabled {
      color: var(--color-neutral-tertiary);
    }
  }
  &--lg {
    .amount__btn {
      width: 1.5rem;
      height: 1.5rem;
      font-size: 0.75rem;
    }
    .amount__value {
      font-size: 1.25rem;
      margin: 0 1rem;
    }
  }
}
</style>
