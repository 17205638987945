<script setup lang="ts">
import { computed, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import Card from '@/components/partials/Card.vue'
import Headline from '@/components/partials/Headline.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import AddReviewForm from './AddReviewForm/AddReviewForm.vue'
import ReviewComment from '@/pages/establishment/components/ReviewComment.vue'
import type { Establishment } from '@/http/models/Establishment'
import type { Review } from '@/types/Review'

defineProps<{
  establishment: Establishment
}>()

const router = useRouter()
const route = useRoute()
const store = useStore()

const review = ref<null | Review>(null)

const form = ref(null)

const { localizedRoute } = useLocalizedRouter()

const navigateToEstablishmentPage = () => {
  router.replace(localizedRoute({ name: 'establishment', params: { country: route.params.country ?? store.state.session.country, city: route.params.city, slug: route.params.slug } }))
}

const { t } = useI18n()

function catchErrorFromForm(code: 409 | 404) {
  if (code === 404) {
    navigateToEstablishmentPage()
  }

  if (code === 409) {
    store.dispatch('modal/show', {
      dataTestId: 'modal-review-already-submitted',
      title: t('modal.review-already-submitted.title'),
      message: t('modal.review-already-submitted.description'),
      closable: false,
      callback: [
        {
          label: t('terms.ok'),
          dataTestId: 'modal-review-already-submitted-ok',
          action: () => navigateToEstablishmentPage(),
        },
      ],
    })
  }
}

function submitted(props) {
  const { reviewer, content, subjects, ratings } = props

  review.value = {
    name: reviewer ?? '',
    content,
    date: new Date(),
    ratings: ratings.map(rating => ({ name: subjects.find(subject => subject.id === rating.review_subject_id)?.title, score: rating.score })),
  }
}

async function goToEstablishmentReviews() {
  const country = route.params.country === '' ? undefined : route.params.country

  await router.push(localizedRoute({
    name: 'establishment',
    params: {
      country: store.state.session.country === country ? undefined : store.state.session.country,
      city: route.params.city,
      slug: route.params.slug,
    },
  }))
}

const open = computed(() => (route.name as string).includes('establishment-add-review'))
</script>

<template>
  <ModalNuevo :open="open" @close="goToEstablishmentReviews">
    <template #modal-header>
      <Headline class="review-modal__title" v-if="review === null">
        {{ t('review-input.title', { establishment: establishment.title }) }}
      </Headline>
      <Headline class="review-modal__title" v-else>
        {{ t('review-input.title-success', { establishment: establishment.title }) }}
      </Headline>
    </template>

    <AddReviewForm ref="form"
                   :establishment="establishment"
                   @submitted="submitted"
                   @error="catchErrorFromForm"
                   data-test-id="establishment-modal-add-review"
                   v-if="review === null" />
    <template v-else>
      <div class="review-modal__succes-message">
        {{ t('review-input.description-success') }}
      </div>
      <Card class="review-modal__review">
        <ReviewComment v-if="review" v-bind="review" />
      </Card>
    </template>

    <template #modal-bottom>
      <template v-if="review === null">
        <Button
          type="text"
          class="review-input__field-no-review cursor-pointer"
          @click="navigateToEstablishmentPage"
        >
          {{ t('review-input.skip') }}
        </Button>
        <Button
          v-if="form"
          data-test-id="submit-review-button"
          @click="form.submit"
        >
          {{ t('review-input.submit') }}
        </Button>
      </template>
      <Button data-test-id="close-review-button"
              @click="navigateToEstablishmentPage"
              v-else>
        {{ t('terms.ok') }}
      </Button>
    </template>
  </ModalNuevo>
</template>

<style lang="scss" scoped>
.review-modal {
  &__title {
    font-size: 1.25rem;
    font-weight: 700;
    padding: 2rem 1rem 1rem 2rem;

    &:deep(hr) {
      margin-bottom: 0;
    }
  }

  &__review {
    padding: 1rem 2rem;
    margin-top: 1rem;
  }

  &__succes-message {
    margin-bottom: 2rem;
  }
}
</style>
