<script setup lang="ts">
import { ref, computed, reactive, onMounted, onUnmounted, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { ClientOnly } from 'vite-plugin-vue-ssr'
import { useThrottleFn } from '@vueuse/core'
import { useStore } from '@/stores'
import CategoryPill from './CategoryPill.vue'
import EstablishmentStatus from '@/pages/establishment/components/EstablishmentStatus.vue'
import DistributionToggle from '@/components/Buttons/DistributionToggle.vue'
import AddressButton from './AddressButton.vue'
import { usePlatform } from '@/composables/platform'
import DistributionTypeDropdown from '@/components/DistributionTypeDropdown.vue'
import { DistributionType, type Establishment } from '@/http/models/Establishment'
import { useEstablishmentHttp } from '@/http/establishmentHttp'
import { useLocalizedRouter } from '@/composables/localizedRouter'
import LoadingRow from '@/components/partials/Loading/LoadingRow.vue'
import Icon from '@/components/partials/Icon.vue'
import type { Location } from '@/types/Location'

type Props = {
  establishment: Establishment | null
}

const props = defineProps<Props>()

const emit = defineEmits(['updateSearch', 'showLocationModal'])

const store = useStore()
const platform = usePlatform()
const { t } = useI18n()
const router = useRouter()
const { localizedRoute } = useLocalizedRouter()

type State = {
  pinned: boolean
  selectedCategoryId: number | null
}

const state = reactive<State>({
  pinned: false,
  selectedCategoryId: null,
})

const container = ref<HTMLElement | null>(null)
const dropdownslider= ref<HTMLElement | null>(null)
const toggleslider = ref<HTMLElement | null>(null)
const hasDragged = ref(false)

const search = ref('')
const searchVisible = ref(false)
const searchInputDesktop = ref<HTMLInputElement | null>(null)
const searchInputMobile = ref<HTMLInputElement | null>(null)

const searchOpen = async (platform:string) => {
  searchVisible.value = true
  if (platform === 'desktop' && searchInputDesktop.value !== null) searchInputDesktop.value.focus()
  if (platform === 'mobile' && searchInputMobile.value !== null) searchInputMobile.value.focus()
}


const clearSearch = () => {
  search.value = ''
  searchVisible.value = false
  searchChange()
}

const searchChange = () => {
  emit('updateSearch', search.value)
}

// Store connections
const distributionTypes = computed(() => props.establishment?.distributionTypes)
const onLocationIdentifier = computed(() => store.getters['session/onLocationIdentifier'])

function easeOutQuad(t: number){
	return -t*(t-2)
}

const scrollingTo = ref(0)
const effect = easeOutQuad
const scrollDuration = 200

const sliderScrollTo = (left: number, slider: 'toggle' | 'dropdown') => {
  if (slider === 'toggle' && toggleslider.value && toggleslider.value.scrollLeft != left){
    scrollToC(toggleslider.value, toggleslider.value.scrollLeft, left, scrollDuration)
  } else if (slider === 'dropdown' && dropdownslider.value && dropdownslider.value.scrollLeft != left) {
    scrollToC(dropdownslider.value, dropdownslider.value.scrollLeft, left, scrollDuration)
  }
}

// Element to move, element or px from, element or px to, time in ms to animate
function scrollToC(element: HTMLElement, from: number | HTMLElement, to: number | HTMLElement, duration:number ) {
  if (duration <= 0) return
  if (typeof from === 'object') from = from.offsetLeft
  if (typeof to === 'object') to = to.offsetLeft
  if (to == scrollingTo.value) return
  scrollingTo.value = to //currently scrolling to
  scrollToX(element, from, to, 0, 1 / duration, 10, effect)
}

function scrollToX(element: HTMLElement, xFrom: number, xTo: number, t01: number, speed: number, step: number, motion) {
  if (xTo != scrollingTo.value) return

  if (t01 < 0 || t01 > 1 || speed <= 0) {
    element.scrollLeft = xTo
    return
  }
  element.scrollLeft = xFrom - (xFrom - xTo) * motion(t01)
  t01 += speed * step

  setTimeout(function() {
    scrollToX(element, xFrom, xTo, t01, speed, step, motion)
  }, step)
}

type Item = {
  id: number
  title: string
  selected: boolean
}

const items = computed<Item[]>(() => {
  if (props.establishment === null || props.establishment.productSets === null) {
    return []
  }

  return props.establishment.productSets.map(e => ({
    id: e.id,
    title: e.title,
    selected: state.selectedCategoryId === e.id,
  }))
})

const scrollActiveTabIntoView = () => {
  if (props.establishment === null) return
  let tab: HTMLElement | undefined

  let slidertype = toggleslider.value && toggleslider.value.offsetWidth === 0 ? 'dropdown' : 'toggle'

  if (slidertype === 'dropdown'){
    if  (dropdownslider.value){
      tab = Array.from(dropdownslider.value.children).find((e) => (e as HTMLElement).getAttribute('data-test-id') === `category-pill-${state.selectedCategoryId}`) as HTMLElement | undefined
    }
    if (!tab) return
    sliderScrollTo(tab.offsetLeft - 5,'dropdown')

  } else {
    if (toggleslider.value) {
      tab = Array.from(toggleslider.value.children).find((e) => (e as HTMLElement).getAttribute('data-test-id') === `category-pill-${state.selectedCategoryId}`) as HTMLElement | undefined
    }
    if (!tab) return
    sliderScrollTo(tab.offsetLeft,'toggle')
  }
}

const cooldown = ref(false)
const handleScroll = useThrottleFn(() => {
  if (cooldown.value) return

  let anyActive = false

  props.establishment?.productSets?.forEach(e => {
    const offset =
      (document.getElementById(`category_${e.id}`)?.offsetTop || 170) - 170

    if (window.scrollY > offset) {
      state.selectedCategoryId = e.id
      anyActive = true
    }
  })

  if (!anyActive) {
    state.selectedCategoryId = null
  } else {
    scrollActiveTabIntoView()
  }
}, 100)

const createSlider = (slider: Ref) => {
  let isDown = false
  let startX = 0
  let scrollLeft = 0

  slider.value.addEventListener('mousedown', (e: MouseEvent) => {
    isDown = true
    slider.value.classList.add('active')
    startX = e.pageX - slider.value.offsetLeft
    scrollLeft = slider.value.scrollLeft
  })

  slider.value.addEventListener('mouseleave', () => {
    isDown = false
    slider.value.classList.remove('active')
    enableClick()
  })

  slider.value.addEventListener('mouseup', () => {
    isDown = false
    slider.value.classList.remove('active')
    enableClick()
  })

  slider.value.addEventListener('mousemove', (e: MouseEvent) => {
    if (!isDown) return
    e.preventDefault()

    const x = e.pageX - slider.value.offsetLeft
    const walk = x - startX // scroll-fast
    slider.value.scrollLeft = scrollLeft - walk

    // if the movement is bigger than 5px, disable the click event triggering a scroll
    if (Math.abs(walk) > 5) {
      hasDragged.value = true
    }
  })
}

const enableClick = () => {
  // Enables click after drag
  setTimeout(() => {
    hasDragged.value = false
  }, 0)
}

let noScrollTimeout: number | null = null

function windowScrollTo(id: string) {
  const offset = document.getElementById(id)

  if (offset === null) return

  window.scrollTo({
    top: offset.offsetTop - 150,
    behavior: 'smooth'
  })
}

function itemClick(item: Item) {
  if (!hasDragged.value) {
    windowScrollTo(`category_${item.id}`)

    state.selectedCategoryId = parseInt(`category-pill-${item.id}`.split('-')[2])

    items.value.forEach((e) => {
      e.selected = e.id === item.id
    })

    scrollActiveTabIntoView()

    cooldown.value = true

    if (noScrollTimeout !== null) {
      clearTimeout(noScrollTimeout)
    }

    // @ts-ignore
    noScrollTimeout = setTimeout(() => {
      cooldown.value = false
    }, 1000)
  }
}

let observer: IntersectionObserver | null = null

onMounted(async () => {
  await nextTick()

  // Scroll behavior of product set selectors
  window.addEventListener('scroll', handleScroll)

  if (!container.value) return
  const containerHeight = container.value.getBoundingClientRect().height

  observer = new IntersectionObserver(
    ([e,]) => {
      if (!container.value) return
      const positionY = container.value.getBoundingClientRect().top
      state.pinned = e.intersectionRatio < 1 && window.scrollY > positionY
      if (container.value) container.value.style.height = state.pinned ? `${containerHeight}px` : 'auto'
    },
    { threshold: 1, }
  )

  observer?.observe(container.value)

  createSlider(dropdownslider)
  createSlider(toggleslider)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  observer?.disconnect()
})

const distribution = computed(() => store.getters['session/distributionType'])

const establishmentHttp = useEstablishmentHttp()

const route = useRoute()

async function toggleDistributionType(event: Event | DistributionType, type: DistributionType) {
  // ugly af
  if (typeof event === 'number' && type === undefined) {
    type = event
  } else {
    // @ts-ignore
    event.preventDefault()
  }

  const location = store.getters['session/location'] as Location | null

  if (type === DistributionType.Delivery && location === null) {
    emit('showLocationModal', { changeDistributionTo: type })

    return
  }

  const country = route.params.country === '' ? undefined : route.params.country

  const distributionInfo = await establishmentHttp.establishmentByDistributionType(
    store.state.establishment.establishment?.slug,
    country ?? store.state.session.country,
    route.params.city,
    type,
    location
  )

  if (type === DistributionType.Delivery && !distributionInfo.is_within_delivery_area) {
    await store.dispatch('modal/show', {
      dataTestId: 'out-of-range-modal',
      title: props.establishment?.title,
      message: t('out-of-range.message'),
      closable: false,
      callback: [
        ...[
        props.establishment?.distributionTypes.includes(
            DistributionType.TakeAway
          )
            ? {
                label: t('out-of-range.btn.get-takeaway'),
                dataTestId: 'out-of-range-modal-takeaway-callback',
                properties: {
                  type: 'secondary',
                },
                action: () => {
                  // in this case, the establishment also supports take away
                  store.dispatch(
                    'session/storeDistributionType',
                    DistributionType.TakeAway
                  )
                },
              }
            : {
                label: t('out-of-range.btn.back-to-overview'),
                dataTestId: 'out-of-range-modal-return-callback',
                properties: {
                  type: 'secondary',
                },
                action: () => {
                  // in this case, the establishment only supports delivery
                  router.push(localizedRoute({ name: 'marketplace' }))
                },
              },
        ],
        {
          label: t('out-of-range.btn.change-address'),
          dataTestId: 'out-of-range-modal-change-address-callback',
          action: () => {
            emit('showLocationModal', { changeDistributionTo: type })
          },
        },
      ],
    })

    return
  }

  await store.dispatch('establishment/setEstablishmentDistribution', distributionInfo)
  await store.dispatch('cart/storeEstablishmentDistribution', distributionInfo)
  await store.dispatch('cart/updateCartByDistribution', type) // updateCartByDistribution updates session/distributionType
  await store.dispatch('marketplace/setCurrentPage', 1)
}

function openAddressModal() {
  emit('showLocationModal')
}
</script>

<template>
  <div ref="container"
       class="toolbar-top"
       data-test-id="toolbar-top"
       :class="{ 'toolbar-top--pinned': state.pinned }"
  >
    <div class="toolbar-top__to-be-pinned"
         data-test-id="establishment-menu"
    >
      <div :class="[ 'toolbar-top__pinned-container', { 'toolbar-top__pinned-container--ios': platform == 'ios', 'toolbar-top__pinned-container--android': platform == 'android' } ]">
        <div v-if="establishment"
             class="toolbar-top__pinned-top"
        >
          <div class="toolbar-top__left">
            <div class="toolbar-top__status">
              <EstablishmentStatus :establishment="establishment" :distribution-type="distribution" />
            </div>
            <div class="toolbar-top__establishment-name-wrapper">
              <div class="toolbar-top__establishment-name">
                {{ establishment?.title }}
              </div>
            </div>
          </div>
          <div class="toolbar-top__right">
            <LoadingRow container-height="40px" fully-rounded container-width="184px" v-if="establishment === null" />
            <DistributionToggle data-test-id="distribution-toggle-mobile"
                                v-model="distribution"
                                @toggle="toggleDistributionType"
                                :distribution-types="distributionTypes"
                                has-tooltip
                                v-else />
          </div>
        </div>

        <!-- SLIDER without dropdown (web) -->
        <div ref="toggleslider" class="toolbar-top__slider with-toggle"
             :class="{ 'pinned': state.pinned }"
        >
        <template v-if="items.length > 0">
          <div :class="[ 'toolbar-top__search', { 'toolbar-top__search-active': searchVisible } ]"  @click.stop="searchOpen('desktop')">
            <Icon  name="search" class="toolbar-top__search-icon" />
            <input ref="searchInputDesktop"
                   v-model="search"
                   @input="searchChange"
                   type="text"
                   class="toolbar-top__input"
                   :class="{ 'toolbar-top__input-active': searchVisible }"
                    />
            <Icon  name="cross-line" @click.stop="clearSearch" v-if="searchVisible" class="toolbar-top__search-icon" />
          </div>
            <CategoryPill v-for="item in items"
                          :key="item.id"
                          :id="item.id"
                          :title="item.title"
                          :selected="item.selected"
                          @click="itemClick(item)"
            />
          </template>
          <template v-else>
            <CategoryPill v-for="i in 5"
                          :key="i"
                          loading
            />
          </template>
        </div>

        <!-- SLIDER with dropdown (mobile) -->
        <div class="toolbar-top__slider-container with-dropdown"
             :class="{ 'pinned': state.pinned }">
          <DistributionTypeDropdown layout="borderless"
                                    v-model="distribution"
                                    :distribution-types="establishment?.distributionTypes!"
                                    class="dist-dropdown"
                                    @dist-change="toggleDistributionType"
                                    v-if="!state.pinned" />
          <div ref="dropdownslider" class="toolbar-top__slider">
            <template v-if="items.length > 0">
              <div class="toolbar-top__search toolbar-top__search-mobile" @click="searchOpen('mobile')">
                <Icon name="search" class="toolbar-top__search-icon"/>
                <input autofocus
                       ref="searchInputMobile"
                       v-model="search"
                       @input="searchChange"
                       type="text"
                       class="toolbar-top__input toolbar-top__input-mobile"
                       :class="{ 'toolbar-top__input-active': searchVisible }"
                       />
                <Icon name="cross-line" @click.stop="clearSearch" v-if="searchVisible" class="toolbar-top__search-icon"/>

              </div>
              <CategoryPill v-for="item in items"
                            :key="item.id"
                            :id="item.id"
                            :title="item.title"
                            :selected="item.selected"
                            @click="itemClick(item)" />
            </template>
            <template v-else>
              <CategoryPill v-for="i in 5"
                            :key="i"
                            loading
              />
            </template>
          </div>
        </div>

        <div class="toolbar-top__desktop-right">
          <ClientOnly>
            <AddressButton @open-modal="openAddressModal" v-if="!onLocationIdentifier" />
            <LoadingRow container-height="40px" fully-rounded container-width="184px" v-if="establishment === null" />
            <DistributionToggle data-test-id="distribution-toggle-desktop"
                                v-model="distribution"
                                @toggle="toggleDistributionType"
                                :distribution-types="establishment.distributionTypes"
                                v-else />
          </ClientOnly>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.toolbar-top {
  $self: &;
  position: relative;
  width: 100%;
  background-color: #F8F8FA;

  @include lg-up {
    background-color: transparent;
  }

  &__search {
    position: relative;
    display: flex;
    gap: 0;
    padding: 0.5rem 1rem;
    border-radius: 9999px;
    background-color: #fff;
    border: #E9ECF2 0.2rem solid;
    transition: width .2s;
    cursor: pointer;

    &-active {
      padding: 0.35rem 1rem;
      gap: 0.5rem;
    }

    &-mobile {
      margin-left: 0.5rem;
      padding: 0.65rem 0.8rem;
      box-shadow: 0 1px 2px 1px rgba(36, 46, 74, 0.1);
      border: none;
    }

    &:hover {
      border-color: var(--color-brand-primary-50);
    }
  }

   &__input {
    border: none;
    width: 0;
    color: var(--color-neutral-primary);
    transition: width .2s;
    padding:0;

    &-active {
      width: 10rem;
      padding-left: 0.5rem;
    }

    &-mobile {
      font-size: 0.875rem;
    }
  }

  &__to-be-pinned {
    width: 100%;
  }

  &__pinned-container {

    @include lg-up {
      display: flex;
      align-items: center;
      gap: 4rem;
      margin: 0 auto;

      // matches grid.scss -> .container
      width: 58rem;
    }

    @include xl-up {
      width: 71rem;
    }

    @include xxl-up {
      width: 83.5rem;
    }

    @include xxxl-up {
      width: 90rem;
    }
  }

  &__pinned-top {
    display: none;
    background-color: #fff;
    align-items: center;
    padding: 0 1.5rem;
    height: 56px;
  }

  &__right {
    margin-left: auto;
  }

  &__desktop-right {
    display: none;

    @include lg-up {
      display: flex;
      flex: 0 0 25rem;
      max-width: 25rem;
      gap: 1rem;
      align-items: center;
    }
  }

  &__left {
    max-width: calc(100% - 185px);

    @include lg-up {
      max-width: calc(100% - 210px - 1rem);
    }
  }

  &__establishment-name-wrapper {
    max-width: 100%;
    margin-top: -0.125rem;
  }

  &__establishment-name {
    font-weight: 700;
    height: 1.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &:deep(.establishment-status) {
    font-weight: 700 !important;
    font-size: 0.875rem;
  }

  &__slider-container {
    position: relative;
    display: flex;

    @include lg-up {
      display: none;
    }

    &:not(.pinned){
      @include sm-up {
        width: 34rem;
        margin: 0 auto;
      }
      @include md-up {
        width: 46rem;
        margin: 0 auto;
      }
      @include lg-up {
        width: 28rem;
      }
      @include xl-up {
        width: 45rem;
      }
      @include xxl-up {
        width: 57.5rem;
      }
      @include xxxl-up {
        width: 64rem;
      }
    }

    .dist-dropdown {
      @include lg-up {
        display: none;
      }
    }
  }

  &__slider {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    user-select: none;
    scrollbar-width: none; /* Firefox */
    overflow-x: scroll;
    scroll-snap-type: x;
    height: 56px;

    &.with-toggle {
      @include lg-down {
        display: none;
      }
    }

    // matches grid.scss -> .container
    @include sm-up {
      width: 34rem;
    }

    @include md-up {
      width: 46rem;
    }

    @include lg-up {
      margin: 0 auto 0 0;
      width: 58rem;
    }

    @include xl-up {
      width: 71rem;
    }

    @include xxl-up {
      width: 83.5rem;
    }

    @include xxxl-up {
      width: 90rem;
    }

    .pill {
      position: relative;
    }

    &:deep(.borderless), &:deep(.pill) {
      &:first-child {
        margin-left: 1rem;

        @include lg-up {
          margin-left: 0;
        }
      }

      &:last-child {
        margin-right: 1rem;

        @include lg-up {
          margin-left: 0;
        }
      }
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
    }
  }

  &--pinned {
    #{ $self }__to-be-pinned {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 50;
      box-shadow: 0 0.5rem 1rem -0.25rem transparentize(#242e4a, 0.85);
      background-color: #F8F8FA;

      @include lg-up {
        background-color: #fff;
      }
    }

    #{ $self }__pinned-container {
      &--ios {
        padding-top: 4rem;
      }
      &--android {
        padding-top: env(safe-area-inset-top);
      }

      background-color: #FFF;
    }

    #{ $self }__pinned-top {
      display: flex;

      @include lg-up {
        display: none;
      }
    }

    #{ $self }__slider {
      background-color: #F8F8FA;
      width: 100%;

      @include lg-up {
        width: auto;
        background-color: #fff;
      }
    }
  }
}
</style>
