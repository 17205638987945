<template>
  <div class="cards-loading-wrapper">
    <div v-if="props.loading">
      <Row>
        <Column class="col-xxl-6">
          <div class="ph-item rounded"
               :style="`height: ${redesign ? '110px' : '150px'}`"
          >
            <div class="ph-row">
              <div class="ph-col-12" />
            </div>
          </div>
        </Column>
        <Column v-if="!props.single"
                class="col-xxl-6"
        >
          <div class="ph-item rounded"
               :style="`height: ${redesign ? '110px' : '150px'}`"
          >
            <div class="ph-row">
              <div class="ph-col-12" />
            </div>
          </div>
        </Column>
      </Row>
    </div>
    <slot v-else />
  </div>
</template>

<script setup>
import Row from '@/components/Structure/Row/Row.vue'
import Column from '@/components/Structure/Column/Column.vue'

const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
  single: {
    type: Boolean,
    default: false,
  },
  redesign: {
    type: Boolean,
    default: false,
  },
})
</script>

<!-- styles found in placeholder-loading.scss -->
<style lang="scss" scoped>
.cards-loading-wrapper {
  width: 100%;
}
</style>
