<script setup lang="ts">
import { computed, nextTick } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import xss from 'xss'
import stripTags, { basicTags } from '@/utils/strip_tags.js'
import ReviewStars from './ReviewStars.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import Icon from '@/components/partials/Icon.vue'
import { formatPrice } from '@/plugins/globals/filters'
import DistributionTypes from '@/config/DistributionTypes'
import type { Establishment } from '@/http/models/Establishment'

type Props = {
  establishment: Establishment
}

const props = defineProps<Props>()

const { t } = useI18n()
const store = useStore()

const distributionType = computed(() => store.getters['session/distributionType'])
const onLocationIdentifier = computed(() => store.getters['session/onLocationIdentifier'])

const description = computed(() => props.establishment.description.join('<br/>'))

const distributionInfo = computed(() => {
  const doesOnLocation = props.establishment.distributionTypes.includes(DistributionTypes.OnLocation)
  const doesTakeAways = props.establishment.distributionTypes.includes(DistributionTypes.TakeAway)
  const doesDeliveries = props.establishment.distributionTypes.includes(DistributionTypes.Delivery)

  const freeDelivery = props.establishment.deliveryCosts === 0
  const hasDeliveryCosts = props.establishment.deliveryCosts > 0
  const undefinedDeliveryCosts = props.establishment.deliveryCosts === null

  const freeDeliveryAndTakeawayInfo =  t('establishment_header.meta.free_delivery_and_takeaway')
  const deliveryCostAndFreeTakeawayInfo = `€ ${formatPrice(props.establishment.deliveryCosts)} ${t('establishment_header.meta.and_free_takeaway')}`
  const deliveryAndTakeawayInfo = t('establishment_header.meta.delivery_and_takeaway')
  const takeawayInfo = t('establishment_header.meta.free_takeaway')
  const onLocationInfo = `${t('establishment_header.meta.on_location')}: ${onLocationIdentifier.value}`

  if (doesDeliveries && !doesTakeAways) return ''

  // Both dist scenario's
  if (doesDeliveries && doesTakeAways && freeDelivery) return freeDeliveryAndTakeawayInfo
  if (doesDeliveries && doesTakeAways && undefinedDeliveryCosts) return deliveryAndTakeawayInfo
  if (doesDeliveries && doesTakeAways && hasDeliveryCosts) return deliveryCostAndFreeTakeawayInfo

  // On Location scenario
  if (doesOnLocation && distributionType.value === DistributionTypes.OnLocation) return onLocationInfo

  // Free Takeaway
  return takeawayInfo
})

const distributionIcon = computed(() => {
  if (distributionType.value === DistributionTypes.Delivery || distributionType.value === DistributionTypes.TakeAway) return 'scooter-line'

  // On Location orders
  return 'shopping-bag-line'
})

const timeInfo = computed(() => `${props.establishment.prepTime} min.`)

const freeDeliveryFromAmount = computed(() => {
  return (props.establishment.distributionTypes.includes(DistributionTypes.Delivery)) ? props.establishment.freeDeliveryStartingAt : null
})

const status = computed(() => props.establishment.overlay?.overlay ?? 'Open')
const color = computed(() => props.establishment.isClosed ? 'red' : props.establishment.overlay?.overlay ? 'grey' : 'green')
</script>

<template>
  <div class="info-box"
       data-test-id="establishment-header-info-box"
  >
    <!-- eslint-disable vue/no-v-html -->
    <div class="info-box__description"
         v-html="xss(stripTags(description, basicTags))"
    />
    <!-- eslint-enable vue/no-v-html -->
    <div class="info-box__meta">
      <ul class="info-box__list">
        <li>
          <ReviewStars :average="establishment ? establishment.reviews.average : 4"
                       :amount="establishment ? establishment.reviews.amount : 56"
          />
        </li>
        <li>•</li>
        <!-- <li>€€€</li> -->
        <!-- <li>•</li> -->
        <li>
          <div :class="['establishment-status', 'establishment-status--no-bubble', `establishment-status--${color}`]">
            <div class="establishment-status__indicator"></div>
            {{ status }}
          </div>
        </li>
        <template v-if="freeDeliveryFromAmount">
          <li>•</li>
          <li>
            {{ t('establishment_header.meta.free_delivery_from', { amount: formatPrice(freeDeliveryFromAmount) }) }}
          </li>
        </template>
        <template v-if="distributionInfo">
          <li>•</li>
          <li>
            <Icon :name="distributionIcon" /> {{ distributionInfo }}
          </li>
        </template>
        <template v-if="distributionType === DistributionTypes.Delivery && timeInfo">
          <li>•</li>
          <li><Icon name="time-line" />{{ timeInfo }}</li>
        </template>
      </ul>
    </div>
    <!-- <div v-if="discounts.length"
         class="info-box__discounts"
         data-test-id="active-discounts"
    >
      <div v-for="discount in discounts"
           :key="discount.id"
           class="info-box__discount"
      >
        <Icon name="star-fill" />
        {{ discount.title }}
      </div>
    </div> -->
    <Button class="info-box__info-button"
            data-test-id="establishment-header-info-box-button"
            icon="info-no-circle"
            type="secondary"
            size="xs"
    />
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.info-box {
  $self: &;
  position: relative;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  transition: all 0.2s;

  @include lg-up {
    border-radius: 0.75rem;
    background-color: #F8F8FA;
    padding: 1rem 1.5rem;
    cursor: pointer;
    margin-right: -8rem;

    &:hover {
      background-color: #E9ECF2;
    }
  }

  @include xl-up {
    margin-right: 2rem;
  }

  @include xxl-up {
    margin-right: -2.75rem;
  }

  &__info-button {
    display: none !important; // TODO: remove the !important after vite css chunk problem is solved

    &:deep(.button__content) {
      border: 0 !important; // TODO: remove the !important after vite css chunk problem is solved
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    @include lg-up {
      display: flex !important; // TODO: remove the !important after vite css chunk problem is solved
      position: absolute !important; // TODO: remove the !important after vite css chunk problem is solved
      top: 0.5rem;
      right: 0.5rem;
    }
  }

  &__description {
    height: 1.5rem;
    width: calc(100% - 2rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__list {
    padding: 0;
    display: flex;
    list-style: none;
    gap: 0.75rem;
    flex-wrap: wrap;
    font-size: 1rem;
    margin: 0.25rem 0 0 0;

    &:first-child {
      margin: 0;
    }

    @include lg-up {
      margin: 0.5rem 0 0 0;
      margin: 0;
    }

    @include xxl-up {
      font-size: 1rem;
    }

    & li {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }

  &__meta {
    @include lg-up {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  &:deep(.establishment-status) {
    font-weight: 700;
  }
}

.establishment-status {
  $self: &;
  display: flex;
  gap: 0.75rem;
  align-items: center;

  &__indicator {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 1rem;
  }

  &--green {
    color: #33cf71;

    #{$self}__indicator {
      background: #33cf71;
    }
  }

  &--orange {
    color: #ff9821;

    #{$self}__indicator {
      background: #ff9821;
    }
  }

  &--red {
    color: var(--color-brand-primary);

    #{$self}__indicator {
      background: var(--color-brand-primary);
    }
  }

  &--grey {
    color: var(--color-neutral-secondary);

    #{$self}__indicator {
      background: var(--color-neutral-secondary-40);
    }
  }

  &--no-bubble {
    #{$self}__indicator {
      display: none;
    }
  }

  &--inline {
    display: inline-flex;
  }
}
</style>
