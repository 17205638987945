import { toOpeningHoursSpecification } from './parse-opening-hours'

const createAddressSpecification = (establishment) => ({
  '@type': 'PostalAddress',
  streetAddress: establishment.location.street,
  addressLocality: establishment.location.city,
  postalCode: establishment.location.zipcode,
  // TODO: dynamic country value if we expand beyond NL
  addressCountry: 'NL'
})


const createPriceRange = (establishment) => {
  const prices = establishment.productSets.map((set) => {
    return set.products.map((product) => product.price)
  }).flat()

  const averagePrice = prices.reduce((acc, price) => acc + price, 0) / prices.length

  // average price between 0 and 10 returns '€'
  if (averagePrice < 10) return '€'
  // average price between 10 and 15 returns '€€'
  if (averagePrice < 17.5) return '€€'
  return '€€€'
}

const createRestaurantSpecification = (establishment) => ({
  '@type': 'Restaurant',
  servesCuisine: establishment.categories
    .map((cat) => cat.name)
    .join(', '),
  hasMenu: {
    '@type': 'Menu',
    hasMenuSection: establishment?.productSets.map((set) => {
      return {
        '@type': 'MenuSection',
        name: set.title,
        description: set.description,
        hasMenuItem: set.products.map((product) => {
          return {
            name: product.title,
            description: product.description,
            image: product.thumb,
            offers: {
              '@type': 'Offer',
              price: product.price,
              priceCurrency: 'EUR',
            },
          }
        }),
      }
    }),
  },
})

const createAggregateRatingSpecification = (establishment) => (
  establishment.reviews.amount > 0
    ? {
      '@type': 'AggregateRating',
      ratingValue: Math.round(establishment.reviews.average * 10) / 10,
      reviewCount: establishment.reviews.amount,
      bestRating: 5,
    }
    : undefined
)

export default (establishment) => {
  let menuJsonLd = createRestaurantSpecification(establishment)

  const aggregateRating = createAggregateRatingSpecification(establishment)

  const openingHoursSpecification = toOpeningHoursSpecification(establishment.openingHours)

  const address = createAddressSpecification(establishment)

  return {
    '@context': 'https://schema.org',
    name: establishment.title,
    telephone: establishment.phone,
    address,
    openingHoursSpecification,
    priceRange: createPriceRange(establishment),
    image: establishment.cover,
    description: establishment.description.join('\n'),
    aggregateRating,
    ...menuJsonLd,
  }
}
