<script setup lang="ts">
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { parseISO } from 'date-fns'
import LoadingWrapper from '@/components/partials/Loading/LoadingWrapper.vue'
import ReviewComment from '@/pages/establishment/components/ReviewComment.vue'
import type { Review } from '@/types/Review'

type Props = {
  reviews: object[] | null
}

const props = defineProps<Props>()

const { t } = useI18n()

type ReviewWithId = Review & { id: number }

const reviews = computed<ReviewWithId[]>(() => {
  if (props.reviews === null) return []

  return props.reviews
    .map(review => ({
      id: review.id,
      name: review.attributes.reviewer,
      date: parseISO(review.attributes.created_at),
      content: review.attributes.content,
      ratings: review.relationships.ratings.map(rating => ({
        name: rating.relationships.review_subject.attributes.title,
        score: rating.attributes.score,
      }))
    }))
})

const loading = computed(() => props.reviews === null)
</script>

<template>
  <div class="reviews">
    <template v-if="loading">
      <div v-for="i in 5" :key="i">
        <p class="reviews__comment-loading">
          <LoadingWrapper width="100%" height="1rem" loading />
        </p>
        <p class="reviews__comment-loading">
          <LoadingWrapper width="100%"  height="2rem" loading />
        </p>
        <hr class="reviews__comment-separator">
      </div>
    </template>

    <template v-else>
      <div v-for="(review, index) in reviews"
           :key="`review_${review.id}`"
           data-test-id="review-comment"
           class="reviews__comment">
        <ReviewComment v-bind="review" />
        <hr v-if="index !== reviews.length - 1" class="reviews__comment-separator">
      </div>

      <em v-if="reviews.length === 0">{{ t('modal-establishment-info.not-enough-reviews') }}</em>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';

.reviews {
  $self: &;
  flex-grow: 1;

  &__comment-loading {
    margin-bottom: 1rem;
  }

  &__comment-separator {
    border: none;
    border-top: 1px solid var(--color-neutral-quaternary);
    margin: 2rem 0;
  }
}
</style>
