<script setup lang="ts">
import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import InfoTab from './InfoTab/InfoTab.vue'
import ReviewsTab from './ReviewsTab/ReviewsTab.vue'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import type { Establishment } from '@/http/models/Establishment'

defineProps<{
  establishment: Establishment
}>()

type Tab = {
  key: string
  label: string
  component: any
}

const route = useRoute()
const router = useRouter()

const tabs: Tab[] = [
  {
    key: 'info',
    label: 'Info',
    component: InfoTab,
  },
  {
    key: 'reviews',
    label: 'Reviews',
    component: ReviewsTab,
  },
]

const current = computed(() => {
  const index = tabs.findIndex(tab => route.query.modal === tab.key)
  return tabs[index] || null
})

async function updateTab(tab: Tab) {
  await router.replace({ query: { modal: tab.key }})
}

async function close() {
 await router.push({ query: { modal: undefined } })
}
</script>

<template>
  <ModalNuevo
    :open="!!current"
    no-padding
    @close="close"
  >
    <template #modal-header>
      <div class="tabs__header">
        <div
          v-for="tab in tabs"
          :key="tab.key"
          :data-test-id="`info-modal-tab-${tab.key}`"
          class="tabs__tab"
          :class="{ 'tabs__tab--active': current && current.key === tab.key }"
          @click="updateTab(tab)"
        >
          {{ tab.label }}
        </div>
      </div>
    </template>
    <div class="tabs">
      <div
        v-if="current"
        class="tabs__content"
      >
        <KeepAlive>
          <component
            :is="current.component"
            :establishment="establishment"
          />
        </KeepAlive>
      </div>
    </div>
  </ModalNuevo>
</template>

<style scoped lang="scss">
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

// Tabs component
.tabs {
  &__header {
    display: flex;
    gap: 1rem;
    padding: 0 2rem;
    user-select: none;
  }

  &__tab {
    padding: 1rem;
    cursor: pointer;
    font-weight: 700;

    &--active {
      border-bottom: 4px solid $new-color-brand-red;
    }
  }
}

// Modal layout

</style>
