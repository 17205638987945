<script setup lang="ts">
import { ref, computed, onMounted } from 'vue'
import { ClientOnly } from 'vite-plugin-vue-ssr'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/stores'
import Card from '@/components/partials/Card.vue'
import type { Establishment } from '@/http/models/Establishment'
import type { Product } from '@/http/models/Product'
import { formatPrice } from '@/plugins/globals/filters'

type Props = {
  data: Product
  establishment: Establishment
}

const props = defineProps<Props>()

const emit = defineEmits(['productSelected'])

const enriched = ref(null)

const product = computed(() => enriched.value === null ? props.data : enriched.value)

const store = useStore()

// TODO fix hydration error, active will be false on the server, but depending on the client state it could be true
const active = computed(() => store.getters['cart/hasBasketItem'](props.data?.id))

const amount = computed(() => store.getters['cart/itemAmountInBasket'](props.data?.id))

async function clickProduct() {
  if (!product.value.available) return

  emit('productSelected', product.value)
}

// this is a hack to force the card to re-render from SSR to CSR
// this can be removed when the basket is SSR ready
const key = ref(0)

onMounted(async () => {
  key.value++
})

const { t } = useI18n()
</script>

<template>
  <Card v-taptic="'medium'"
        :key="key"
        data-test-id="dish-card"
        clickable
        :active="active"
        :class="[ 'dish-card', { 'dish-card--with-image': !!data.thumb, 'dish-card__unavailable': !product.available } ]"
        @click="clickProduct">
    <div class="dish-card__padded">
      <div class="dish-card__body">
        <div class="dish-card__title" :class="{ 'dish-card__title--unavailable': !product.available }">
          <ClientOnly>
            <span v-if="amount" class="dish-card__amount">
              {{ amount }}
              <span class="dish-card__amount__times">x</span>
            </span>
          </ClientOnly>
          {{ data.title }}
        </div>
        <div class="dish-card__description">
          {{ data.description }}
        </div>
        <div class="dish-card__price">
          <div class="dish-card__unavailable--pill" v-if="!product.available">
            <p>{{ t('terms.not-available') }}</p>
          </div>
          <template v-else>
            <template v-if="data.discount">
              <div class="dish-card__price-item dish-card__price-item--new"
                  data-test-id="dish-card-discount-price"
                  data-hj-allow>
                € {{ formatPrice(data.price) }}
              </div>
              <div class="dish-card__price-item dish-card__price-item--old"
                  data-test-id="dish-card-price"
                  data-hj-allow
              >
                € {{ formatPrice(data.discount.oldPrice) }}
              </div>
            </template>
            <template v-else>
              <span data-hj-allow>€ {{ formatPrice(data.price) }}</span>
            </template>
          </template>
        </div>
      </div>
      <div class="dish-card__thumb" :class="{'dish-card__thumb--unavailable': !product.available}">
        <img v-if="data.thumb" loading="lazy" :alt="data.title" :src="data.thumb" class="" />
      </div>
    </div>
  </Card>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables/variables.scss';
@import '@/assets/css/mixins/styling.scss';
@import '@/assets/css/mixins/interactions.scss';
@import '@/assets/css/mixins/breakpoints-up.scss';

.dish-card {
  $self: &;
  line-height: 1.5;
  position: relative;
  display: flex;
  height: 100%;
  cursor: pointer;
  box-shadow: none;

  // TODO: replace with rem when html is updated, as 1rem is now 14px
  font-size: 16px;
  margin: 0 -0.5rem;

  @include lg-up {
    margin: 0;
  }

  &__padded {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: stretch;
    width: 100%;
    padding: 1rem;
    background-color: #fff;
    border-radius: 0.95rem;
  }

  &__unavailable {
    filter: brightness(100%);
    color: #fff;
    font-weight: 400;

    &--pill {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.2rem 0.5rem;
      background: var(--color-neutral-secondary);
      border-radius: 6px;
      text-align: center;
    }
    &--image{
      width: 1rem;
    }
  }

  &__thumb {
    position:            relative;
    flex:                0 0 auto;
    background-size:     cover;
    border-radius:       0.5rem;
    background-position: center center;
    height:              100%;
    min-width:           6rem;
    min-height:          6rem;
    transition:          transform 120ms ease-out;
    overflow:            hidden;

    img {
      aspect-ratio: 1/1;
      position:     absolute;
      right:        0;
      height:       100%;
      object-fit:   cover;
    }
    &--unavailable{
      filter: opacity(50%);
    }
  }

  &__body {
    margin-right: 0.5rem;
    flex-grow: 1;
    margin: auto 0;
  }

  &__amount {
    margin-right: 0.25rem;
    font-weight: 300;

    &__times {
      margin: -0.5rem 0 0 -0.175rem;
    }
  }

  &__title {
    font-weight: 700;

    &--unavailable{
      color: #cbcbcb
    }
  }

  &__description {
    @include line-md;
    max-height: 4.3rem;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 300;
    font-size: 14px;
    color: var(--color-neutral-secondary);
  }

  &__price {
    margin-top: 0.5rem;
    display: flex;
    gap: 0.5rem;
    align-items: center;
      font-size: 14px;
  }

  &__price-item {
    font-weight: 300;
    font-size: 16px;

    &--old {
      font-size: 14px;
      color: var(--color-neutral-primary);
      text-decoration: line-through;
    }

    &--new {
      @include gradient-button;
      padding: 0rem 0.5rem;
      border-radius: 0.25rem;
      font-weight: 700;
      color: #fff;
    }
  }

  &--with-image {
    #{ $self }__body {
      padding-right: 1rem;
    }
  }

  // card adjustments for redesign
  &.card {
    border: none;
    padding: 1px;
    border-radius: 1rem;
    background-color: #E9ECF2;
    transition: background 0.125s;
    box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.025);

    @include lg-up {
      padding: 2px;
    }

    &:before {
      content: '';
      z-index: 1;
      position: absolute;
      top: -0.5rem;
      left: -0.5rem;
      border-radius: 1.5rem;
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      @include gradient-button($new-gradient-brand-main-10);
      transform: scale(0.75);
      transition: all 0.2s;
    }

    &:hover {
      background-color: var(--color-brand-primary-50);

      &:before {
        transform: scale(1);
      }
    }

    &--active {
      @include gradient-button;
    }
  }
}
</style>
