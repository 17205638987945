const CLOSED = 'Closed'

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

// Merge multiple opening and closing hours on the same day.
// Take the "start" of the first record and the "end" of the last record.
const mergeOpeningHoursOnDay = (hours) => 
  Object.keys(hours).reduce((acc, day) => {
    // check if the day is not missing in the array
    if (!hours[day].length) {
      return {
        ...acc,
        [day]: CLOSED
      }
    }

    return {
      ...acc,
      [day]: { 
        start: hours[day][0].start, 
        end: hours[day][hours[day].length - 1].end 
      }
    }
}, {})

// Merge the opening hours for all distribution types to represent a single week
// Takes in an array of distribution type opening hours and converts it into an object like { [dayN]: { start: '09:00', end: '23:00' } }
const mergeDistributionOpeningHours = (distributionOpeningHours) => 
  Object.values(distributionOpeningHours).reduce((acc, hoursForSingleDistribution) => {
    const mergedHoursForDistribution = mergeOpeningHoursOnDay(hoursForSingleDistribution)

    const updateDateWithTime = (date, timeString) => {
      const [hours, minutes] = timeString.split(':')
      date.setHours(Number(hours), Number(minutes))
    }

    // Compare the start and end time of the days of the week. Merge them all together.
    Object.keys(mergedHoursForDistribution).forEach((day) => {
      // In case the day does not yet exist in the acc object, update with the first distribution opening hours
      if (!acc[day]) return (acc[day] = mergedHoursForDistribution[day])

      // The establishment can also be closed on certain days 
      if (acc[day] === CLOSED) return CLOSED
      if (mergedHoursForDistribution[day] === CLOSED) return acc[day]

      // else, we need to compare the current value with the new values to get the bounds
      const currentStartTime = new Date()
      const compareStartTime = new Date()

      updateDateWithTime(currentStartTime, acc[day].start)
      updateDateWithTime(compareStartTime, mergedHoursForDistribution[day].start)

      const currentEndTime = new Date()
      const compareEndTime = new Date()

      updateDateWithTime(currentEndTime, acc[day].end)
      updateDateWithTime(compareEndTime, mergedHoursForDistribution[day].end)

      acc[day] = {
        start: currentStartTime < compareStartTime ? acc[day].start : mergedHoursForDistribution[day].start, 
        end: currentEndTime > compareEndTime ? acc[day].end : mergedHoursForDistribution[day].end,
      }
    })

    return acc
  }, {})

// Converts an object with day keys into an array of objects, grouping them by having the same opening and closing hours
const mergeDaysWithSameOpeningHours = (weeklyOpeningHours) => Object.entries(weeklyOpeningHours).reduce((acc, [day, hours]) => {
  // Check if the start and end time are already in a group. If so, push the day to that group.
  const isInAnyGroup = acc.reduce((inAnyGroup, group) => {
    const match = group.start === hours.start && group.end === hours.end
    const isClosed = group.closed && hours === CLOSED
  
    if (match || isClosed) {
      group.days.push(day)
      return true
    }

    return inAnyGroup
  }, false)

  // If the start and end time are not matched, create a new group.
  if (!isInAnyGroup) {
    acc.push({
      start: hours === CLOSED ? undefined : hours.start,
      end: hours === CLOSED ? undefined : hours.end,
      closed: hours === CLOSED,
      days: [day],
    })
  }
  
  return acc
}, [])

export const toOpeningHoursSpecification = (distributionOpeningHours) => {
  const weeklyOpeningHours = mergeDistributionOpeningHours(distributionOpeningHours)
  const mergedDaysByOpeningHours = mergeDaysWithSameOpeningHours(weeklyOpeningHours)

  return mergedDaysByOpeningHours
    // Filter out the days on which the establishment is closed
    .filter(({ closed }) => !closed)
    // Convert to the OpeningHoursSpecification format https://developers.google.com/search/docs/appearance/structured-data/local-business#business-hours 
    .map(({ start, end, days }) => ({
      "@type": "OpeningHoursSpecification",
      dayOfWeek: days.map((day) => capitalize(day)),
      opens: start,
      closes: end,
    }))
}
